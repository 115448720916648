import React from "react";
import { variables } from "../../../cssInJs";
import { useParams } from "react-router-dom";
import env from "../../../env";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../../store";
import clsx from "clsx";
import * as yup from "yup";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Avatar,
  Tab,
  Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  viewicon: {
    cursor: "pointer",
  },
  checkbox: {
    //backgroundColor : 'yellow',
    //accent-color: #9b59b6;
    //fill : 'yellow'
    "& path": {
      fill: "orange",
    },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    //margin: theme.spacing(1),
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: `-webkit-fill-available`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  formControl2: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    handleOpenCommit,
    rowCount,
    //currentTab,
    //activeStatus,
    //inActiveStatus,
    //deleteItems,
    //studioId,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Certificates
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        {/* <Tooltip title="Create Belt Size" arrow>
            <Button component={Link} to={`/dashboard/belt-tying-video/new`}>
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip> */}

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        {/* <Tooltip title="Active" arrow>
            <Button onClick={activeStatus}>
              <CheckCircleOutlineIcon fontSize="small" />
            </Button>
          </Tooltip>
         
          <Tooltip title="In Active" arrow>
            <Button onClick={inActiveStatus}>
              <RemoveCircleIcon fontSize="small" />
            </Button>
          </Tooltip>
          
          <Tooltip title="Delete" arrow>
            <Button onClick={deleteItems}>
              <DeleteIcon fontSize="small" />
            </Button>
          </Tooltip> */}

        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCommit}
          disabled={numSelected <= 0}
        >
          Commit
        </Button>
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, searchType } = props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Studio Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={190}
        />
      </FormControl>
    </form>
  );
};

const headCells = [
  {
    id: "View",
    numeric: false,
    disablePadding: false,
    label: "View",
    hasSort: false,
  },
  {
    id: "ProfilePic",
    numeric: false,
    disablePadding: false,
    label: "Profile Pic",
    hasSort: false,
  },
  {
    id: "FirstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    hasSort: false,
  },
  {
    id: "LastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    hasSort: false,
  },
  {
    id: "StudentId",
    numeric: false,
    disablePadding: false,
    label: "Student Id",
    hasSort: false,
  },
  {
    id: "Studio",
    numeric: false,
    disablePadding: false,
    label: "Studio",
    hasSort: false,
  },
  {
    id: "HouseTeam",
    numeric: false,
    disablePadding: false,
    label: "House Team",
    hasSort: false,
  },
  {
    id: "Type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    hasSort: false,
  },
  {
    id: "Belt",
    numeric: false,
    disablePadding: false,
    label: "Belt",
    hasSort: false,
  },
  {
    id: "NewBelt",
    numeric: false,
    disablePadding: false,
    label: "New Belt",
    hasSort: false,
  },
  {
    id: "Action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    hasSort: false,
  },
  {
    id: "ClassActions",
    numeric: false,
    disablePadding: false,
    label: "Class Actions",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected,
    rowCount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>

        <TableCell padding="checkbox">Done</TableCell>

        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const UndoDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    // open,
    // handleCloseNewClassDialog,
    // studentRegoTermReportId,
    // //terms,
    // programs,
    // setValue,
    // trigger,
    // handleGetBatchs,
    // getValues,
    // batches,
    // isSubmitting,
    // handleSubmit,
    // submitNewClassAssignment,
    // errors,
    // nextBatch,
    // removeNewClass,
    saveReasonNotPromoStatus,
    handleSubmit,
    isSubmitting,
    reasonNotPromoTypes,
    control,
    handleCloseUndo,
    undoOpen,
    register,
    errors,
  } = props;

  //const { programId, batchId } = getValues();
  //const _batch = (batches || []).find((x) => x.batchId === batchId) || null;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={undoOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        onSubmit={handleSubmit((dataResult) =>
          saveReasonNotPromoStatus(dataResult)
        )}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6"> Reason for Not Promoting </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseUndo}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl
            component="fieldset"
            className={classes.textField}
            size="small"
            error={!!errors.reasonNotPromoType}
          >
            <Controller
              control={control}
              name="reasonNotPromoType"
              aria-label="reasonNotPromoType"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {(reasonNotPromoTypes || []).map(
                    (reasonNotPromoType, index) => {
                      return (
                        <FormControlLabel
                          key={reasonNotPromoType.value}
                          value={reasonNotPromoType.value + ""}
                          control={<Radio color="primary" size="small" />}
                          label={reasonNotPromoType.name}
                        />
                      );
                    }
                  )}
                </RadioGroup>
              )}
            />

            {!!register.criterionId && (
              <FormHelperText error id="endDate-error">
                {register?.criterionId?.message}
              </FormHelperText>
            )}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CommitDialog = (props) => {
  const { commitDialogOpen, handleCloseCommit, handleRunCommit } = props;
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={commitDialogOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogContent dividers>
        Are you sure you want to commit and clear this report? If you proceed
        the data will be cleared as part of Term Closure!
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleRunCommit}>
          Yes
        </Button>
        <Button color="primary" onClick={handleCloseCommit}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};
const schema = yup.object().shape({
  search: yup.string(),
});

const schemaUndo = yup.object().shape({
  //programId: yup.string().required("Criterion is required."),
  //batchId: yup.string().required("Criterion is required."),
  reasonNotPromoType: yup.string().required("Status is required."),
});

export function Certificates() {
  const { studioId } = useParams();
  const classes = useStyles();
  const { dispatch } = useStore();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: false,
    commitDialogOpen: false,
    undoOpen: false,
    programTabId: null,
    student: null,
    order: "asc",
    orderBy: "FirstName",
    selected: [],
  });
  const previousController = React.useRef();
  const timerController = React.useRef();

  const { register } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerUndo,
    control: controlUndo,
    reset: resetUndo,
    handleSubmit: handleSubmitUndo,
    formState: { isSubmitting: isSubmittingUndo, errors: errorsUndo },
  } = useForm({
    resolver: yupResolver(schemaUndo),
    defaultValues: { reasonNotPromoType: "0" },
  });

  const apiCall = async (
    url,
    apiData,
    returnFunction = null,
    retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        if(retrunErrorFunction){
          retrunErrorFunction();
        }
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleChangeProgramTabId = (event, newValue) => {
    setFuntionInfo({
      ...funtionInfo,
      programTabId: newValue,
    });

    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";
    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
    // if (getApiResult.appManages && getApiResult.appManages.length > 0) {
    //   setBackdropOpen(true);
    //   setApiTrigger(apiTrigger + 1);
    // }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getApiResult.students.map(
        (n) => n.studentRegoTermReportId
      );
      setFuntionInfo({
        ...funtionInfo,
        selected: newSelecteds,
      });
      return;
    }

    setFuntionInfo({
      ...funtionInfo,
      selected: [],
    });
  };

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const isSelected = (name) => funtionInfo.selected.indexOf(name) !== -1;

  const handleCheckBoxChange = (event, studentRegoTermReportId) => {
    const selectedIndex = funtionInfo.selected.indexOf(studentRegoTermReportId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        funtionInfo.selected,
        studentRegoTermReportId
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(funtionInfo.selected.slice(1));
    } else if (selectedIndex === funtionInfo.selected.length - 1) {
      newSelected = newSelected.concat(funtionInfo.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        funtionInfo.selected.slice(0, selectedIndex),
        funtionInfo.selected.slice(selectedIndex + 1)
      );
    }

    setFuntionInfo({
      ...funtionInfo,
      selected: newSelected,
    });
  };

  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  const handleDoneCheckBoxChange = async (event, studentRegoTermReportId) => {
    await apiCall("/api/term/updatecertificatedone", {
      checkDone: event.target.checked,
      studentRegoTermReportId: studentRegoTermReportId,
    });
  };

  const handleOpenUndo = (student) => {
    resetUndo({
      reasonNotPromoType: student.reasonNotPromoType + "",
    });
    setFuntionInfo({
      ...funtionInfo,
      undoOpen: true,
      student: student,
    });
  };

  const handleCloseUndo = () => {
    setFuntionInfo({
      ...funtionInfo,
      undoOpen: false,
      student: null,
    });
  };

  const saveReasonNotPromoStatus = async (dataModel) => {
    apiCall(
      "/api/term/SaveReasonNotPromoStatus",
      {
        ...dataModel,
        studentRegoTermReportId: funtionInfo.student.studentRegoTermReportId,
      },
      () => {
        setFuntionInfo({
          ...funtionInfo,
          undoOpen: false,
          student: null,
        });
        setApiTrigger(apiTrigger + 1);
        setTimeout(() => {
          document.activeElement.blur();
        }, 500);
      }
    );
  };

  const handleOpenCommit = () => {
    setFuntionInfo({
      ...funtionInfo,
      commitDialogOpen: true,
    });
  };

  const handleCloseCommit = () => {
    setFuntionInfo({
      ...funtionInfo,
      commitDialogOpen: false,
    });
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const handleRunCommit = async () => {
    await apiCall(
      "/api/term/updatecertificatesactionchecks",
      {
        studentRegoTermReportIds: funtionInfo.selected,
      },
      () => {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        setFuntionInfo({
          ...funtionInfo,
          selected: [],
          commitDialogOpen: false,
        });
        setApiTrigger(apiTrigger + 1);
      },
      () => {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        setFuntionInfo({
          ...funtionInfo,
          commitDialogOpen: false,
        });
      }
    );
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/term/getcertificatereports",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //...getValues(),
              // page: page,
              // rowsPerPage: rowsPerPage,
              // order: order,
              // orderBy: orderBy,
              // type: 5,
              //programId: programIdTab,
              programId: funtionInfo.programTabId,
              studioId: studioId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Certificates", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <UndoDialog
        {...funtionInfo}
        {...getApiResult}
        isSubmitting={isSubmittingUndo}
        handleCloseUndo={handleCloseUndo}
        register={registerUndo}
        handleSubmit={handleSubmitUndo}
        saveReasonNotPromoStatus={saveReasonNotPromoStatus}
        errors={errorsUndo}
        control={controlUndo}
      />

      <CommitDialog
        {...funtionInfo}
        handleCloseCommit={handleCloseCommit}
        handleRunCommit={handleRunCommit}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={funtionInfo.selected.length}
          handleFilter={handleFilter}
          rowCount={getApiResult.students.length}
          handleOpenCommit={handleOpenCommit}
        />

        {funtionInfo.filterOpen && (
          <SearchPanel register={register} searchType={searchType} />
        )}

        {getApiResult.programs && getApiResult.programs.length > 0 && (
          <AppBar position="static">
            <Tabs
              value={
                funtionInfo.programTabId || getApiResult.programs[0].programId
              }
              onChange={handleChangeProgramTabId}
              aria-label="wrapped label tabs example"
            >
              {getApiResult.programs.map((row, index) => {
                return (
                  <Tab
                    key={row.programId}
                    value={row.programId}
                    label={row.programName}
                    wrapped
                    {...a11yProps(row.programId)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={funtionInfo.selected.length}
              order={funtionInfo.order}
              orderBy={funtionInfo.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={getApiResult.students.length}
            />

            <TableBody>
              {getApiResult.students.map((studentRow, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const labelId2 = `enhanced-table-checkbox2-${index}`;
                const isItemSelected = isSelected(
                  studentRow.studentRegoTermReportId
                );
                const _done = studentRow.certificateStatus === 3 ? true : false;
                console.log("studentRow", studentRow);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={studentRow.studentRegoTermReportId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={(event) =>
                          handleCheckBoxChange(
                            event,
                            studentRow.studentRegoTermReportId
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId2}
                      scope="row"
                      padding="checkbox"
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={_done}
                        inputProps={{ "aria-labelledby": labelId2 }}
                        onChange={(event) =>
                          handleDoneCheckBoxChange(
                            event,
                            studentRow.studentRegoTermReportId
                          )
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      {" "}
                      <DescriptionIcon
                        fontSize="small"
                        className={classes.viewicon}
                      />{" "}
                    </TableCell>
                    <TableCell align="left">
                      <Avatar src="/broken-image.jpg" />
                    </TableCell>
                    <TableCell align="left"> {studentRow.firstName} </TableCell>
                    <TableCell align="left"> {studentRow.lastName} </TableCell>
                    <TableCell align="left"> - </TableCell>
                    <TableCell align="left">
                      {" "}
                      {getApiResult.studioInfo.studioName}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {studentRow.houseTeamName}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {studentRow.disPlayPromotionType}
                    </TableCell>
                    <TableCell align="left">{studentRow.beltName}</TableCell>
                    <TableCell align="left">
                      {" "}
                      {studentRow.newBeltLevelName
                        ? studentRow.newBeltLevelName
                        : "N/A"}{" "}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleOpenUndo(studentRow);
                        }}
                      >
                        UNDO
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      {studentRow.actionCheck ? (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: "green" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
