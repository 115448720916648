import React from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage, Loading, SnackbarMessageV2 } from "components";
import env from "../../../env";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { variables } from "../../../cssInJs";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
//import DeleteIcon from "@material-ui/icons/Delete";
//import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EmailIcon from '@material-ui/icons/Email';
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import utils from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStore } from "../../../store";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //ListItemIcon,
  TextField,
  Backdrop,
  Dialog,
  Select,
  DialogTitle,
  //List,
  //ListItem,
  //ListItemText,
  //DialogTitle,
  DialogContent,
  //DialogContentText,
  //Box,
  DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    //margin: theme.spacing(1),
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    handleOpenCommit,
    rowCount
    //currentTab,
    //activeStatus,
    //inActiveStatus,
    //deleteItems,
    //studioId,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Upgrades
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        {/* <Tooltip title="Create Belt Size" arrow>
          <Button component={Link} to={`/dashboard/belt-tying-video/new`}>
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        {/* <Tooltip title="Active" arrow>
          <Button onClick={activeStatus}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
       
        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveStatus}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>
        
        <Tooltip title="Delete" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenCommit}
          disabled={rowCount <= 0}
        >
          Commit
        </Button>
      </ButtonGroup>
    </Toolbar>
  );
};

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: `-webkit-fill-available`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  formControl2: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
}));

const headCells = [
  {
    id: "Image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    hasSort: false,
  },
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    hasSort: false,
  },
  {
    id: "Age",
    numeric: false,
    disablePadding: false,
    label: "Age",
    hasSort: false,
  },
  {
    id: "Belt",
    numeric: false,
    disablePadding: false,
    label: "Belt",
    hasSort: false,
  },
  {
    id: "NextUniform",
    numeric: false,
    disablePadding: false,
    label: "Next Uniform",
    hasSort: false,
  },
  {
    id: "Confirmed",
    numeric: false,
    disablePadding: false,
    label: "Confirmed",
    hasSort: false,
  },
  {
    id: "Measured",
    numeric: false,
    disablePadding: false,
    label: "Measured",
    hasSort: false,
  },
  {
    id: "Size",
    numeric: false,
    disablePadding: false,
    label: "Size",
    hasSort: false,
  },
  {
    id: "NewClass",
    numeric: false,
    disablePadding: false,
    label: "New Class",
    hasSort: false,
  },
  {
    id: "SendEmail",
    numeric: false,
    disablePadding: false,
    label: "Send Email",
    hasSort: false,
  },
  {
    id: "Action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, searchType } = props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Studio Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={190}
        />
      </FormControl>
    </form>
  );
};

const NewClassDialog = (props) => {

  const classes = useCoDialogStyles();
  const {
    open,
    handleCloseNewClassDialog,
    studentRegoTermReportId,
    //terms,
    programs,
    setValue,
    trigger,
    handleGetBatchs,
    getValues,
    batches,
    isSubmitting,
    handleSubmit,
    submitNewClassAssignment,
    errors,
    nextBatch,
    removeNewClass,
  } = props;

  const { programId, batchId } = getValues();
  const _batch = (batches || []).find((x) => x.batchId === batchId) || null;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit((dataResult) =>
          submitNewClassAssignment(dataResult)
        )}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6"> New Class Assignment </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseNewClassDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            id="programId"
            options={programs || []}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.programName}
            getOptionSelected={(option, value) =>
              option.programId === value.programId
            }
            value={
              (programs || []).find((x) => x.programId === programId) || null
            }
            onChange={(event, newValue) => {
              setValue("programId", newValue ? newValue.programId : null);
              trigger("programId");
              handleGetBatchs();
            }}
            renderOption={(option) => (
              <React.Fragment>{option.programName}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Program *"
                variant="outlined"
                size="small"
                error={!!errors.programId}
                helperText={errors?.programId?.message}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Autocomplete
            id="batchId"
            options={batches || []}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.batchName}
            getOptionSelected={(option, value) =>
              option.batchId === value.batchId
            }
            value={_batch}
            onChange={(event, newValue) => {
              setValue("batchId", newValue ? newValue.batchId : null);
              trigger("batchId");
            }}
            renderOption={(option) => (
              <React.Fragment>{option.batchName}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Batch *"
                variant="outlined"
                size="small"
                error={!!errors.batchId}
                helperText={errors?.batchId?.message}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />

          <div className={classes.datePicker}>
            <Typography variant="caption"> Start Date </Typography>
            <DatePicker
              selected={_batch ? new Date(_batch.startDate) : null}
              disabled
              showIcon
              isClearable
              dateFormat="dd/MM/yyyy"
              placeholderText={"dd/mm/yyyy"}
              toggleCalendarOnIconClick
            />
          </div>
          <div className={classes.datePicker}>
            <Typography variant="caption"> End Date </Typography>
            <DatePicker
              selected={_batch ? new Date(_batch.endDate) : null}
              disabled
              showIcon
              isClearable
              dateFormat="dd/MM/yyyy"
              placeholderText={"dd/mm/yyyy"}
              toggleCalendarOnIconClick
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
          {nextBatch && (
            <Button
              color="primary"
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              disabled={isSubmitting}
              onClick={() => {
                removeNewClass(studentRegoTermReportId);
              }}
            >
              Remove
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CommitDialog = (props) => {
  //const classes = useCoDialogStyles();
  const { open, handleCloseCommit, handleRunCommit } = props;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogContent dividers>
        Are you sure you want to commit and clear this report? If you proceed
        the data will be cleared as part of Term Closure!
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleRunCommit}>
          Yes
        </Button>
        <Button color="primary" onClick={handleCloseCommit}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const schema = yup.object().shape({
  search: yup.string(),
});

const schema2 = yup.object().shape({
  programId: yup.string().required("Criterion is required."),
  batchId: yup.string().required("Criterion is required."),
});

export function Upgrades() {
  const { studioId } = useParams();
  const { dispatch } = useStore();
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Title");
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [getApiResult, setGetApiResult] = React.useState();
  const [programIdTab, setProgramIdTab] = React.useState();

  const [newClassDialogInfo, setNewClassDialogInfo] = React.useState({
    open: false,
  });
  const [commitDialogInfo, setCommitDialogInfo] = React.useState({
    open: false,
  });
  const [openSnackbarMessage, setOpenSnackbarMessage] = React.useState({
    openStatus: false,
    message: null,
  });

  const {
    //getValues,
    //setValue,
    //formState: { isSubmitting, errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const {
    //register: register2,
    handleSubmit: handleSubmit2,
    //control: control2,
    //watch: watch2,
    trigger: trigger2,
    reset: reset2,
    getValues: getValues2,
    setValue: setValue2,
    formState: { isSubmitting: isSubmitting2, errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (getApiResult.appManages && getApiResult.appManages.length > 0) {
      setBackdropOpen(true);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const apiCall = async (url, apiData, returnFunction = null, retrunErrorFunction = null) => {
    setBackdropOpen(true);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setOpenSnackbarMessage({
          openStatus: true,
          message: data.errors[0].errorMessage,
        });
        setBackdropOpen(false);
        if(retrunErrorFunction){
          retrunErrorFunction();
        }
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setBackdropOpen(false);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const handleChangeNewUniform = (event, studentRegoId) => {
    console.log("event.target.value", event.target.value);
    apiCall("/api/student/updatestudentregonextuniform", {
      nextUniform: event.target.value === "yes" ? true : false,
      studentRegoId: studentRegoId,
    });
  };

  const handleChangeConfirm = (event, studentRegoTermReportId) => {
    console.log("event.target.value", event.target.value);
    apiCall("/api/term/updateupgradeconfirmstatus", {
      updateConfirmStatus: event.target.value,
      studentRegoTermReportId: studentRegoTermReportId,
    });
  };

  const handleChangeBeltSize = (event, studentRegoId) => {
    console.log("event.target.value", event.target.value);
    apiCall("/api/student/updatestudentregobeltsize", {
      beltSizeId: event.target.value,
      studentRegoId: studentRegoId,
    });
  };

  const handleOpenNewClassDialog = async (
    currentProgramId,
    updateConfirmStatus,
    studentRegoTermReportId,
    nextBatchId
    //assignClassId,
    //newClassId
  ) => {
    if (updateConfirmStatus !== 1) {
      setOpenSnackbarMessage({
        openStatus: true,
        message: "Please confirm",
      });
      return;
    }

    await apiCall(
      "/api/term/getnewclass",
      {
        studioId: studioId,
        currentProgramId: currentProgramId,
        nextBatchId: nextBatchId,
      },
      (result) => {
        if (result && result.nextBatch) {
          reset2({
            programId: result.nextBatch.programId,
            batchId: result.nextBatch.batchId,
          });
        }

        setNewClassDialogInfo({
          open: true,
          studentRegoTermReportId: studentRegoTermReportId,
          ...result,
        });
      }
    );
  };

  const handleCloseNewClassDialog = () => {
    reset2({
      programId: null,
      batchId: null,
    });

    setNewClassDialogInfo({
      open: false,
    });

    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const handleGetBatchs = async () => {
    const { programId } = getValues2();
    if (!programId) {
      return;
    }
    await apiCall(
      "/api/student/getbatchesnewclassassignment",
      {
        programId: programId,
        studioId: studioId,
      },
      (result) => {
        setNewClassDialogInfo({
          ...newClassDialogInfo,
          ...result,
        });
      }
    );
  };

  const handleCloseFunction = () => {
    setOpenSnackbarMessage(false);
  };

  const submitNewClassAssignment = async (dataModel) => {
    await apiCall(
      "/api/term/updatenextbatch",
      {
        ...dataModel,
        studentRegoTermReportId: newClassDialogInfo.studentRegoTermReportId,
      },
      (result) => {
        reset2({
          programId: null,
          batchId: null,
        });
        setNewClassDialogInfo({
          open: false,
        });
        setApiTrigger(apiTrigger + 1);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    );
  };

  const removeNewClass = async (studentRegoTermReportId) => {
    await apiCall(
      "/api/term/removenewclass",
      {
        studentRegoTermReportId: studentRegoTermReportId,
      },
      (result) => {
        reset2({
          programId: null,
          batchId: null,
        });
        setNewClassDialogInfo({ open: false });
        setApiTrigger(apiTrigger + 1);
        setTimeout(() => {
          document.activeElement.blur();
        }, 500);
      }
    );
  };

  const handleOpenCommit = () => {
    setCommitDialogInfo({
      open: true,
    });
  };

  const handleCloseCommit = () => {
    setCommitDialogInfo({
      open: false,
    });
    setTimeout(() => {
      document.activeElement.blur();
    }, 500);
  };

  const handleRunCommit = async () => {
    const studentRegoTermReportIds = getApiResult.students.map(
      (x) => x.studentRegoTermReportId
    );

    await apiCall(
      "/api/term/updateupgradeactionchecks",
      {
        studentRegoTermReportIds : studentRegoTermReportIds
      },
      (result) => {
        setCommitDialogInfo({
          open: false,
        });
        setApiTrigger(apiTrigger + 1);
        setTimeout(() => {
          document.activeElement.blur();
        }, 500);
      },
      () => {
        setCommitDialogInfo({
          open: false,
        });
      }
    );
  };

  const handleChangeProgramTab = (event, newValue) => {
    setProgramIdTab(newValue);

    setBackdropOpen(true);
    setApiTrigger(apiTrigger + 1);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/term/getupgradetermreport",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //...getValues(),
              // page: page,
              // rowsPerPage: rowsPerPage,
              // order: order,
              // orderBy: orderBy,
              // type: 5,
              programId: programIdTab,
              studioId: studioId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          setBackdropOpen(false);
          return;
        }

        if (data.results) {
          const { selectedProgramId } = data.results;
          setProgramIdTab(selectedProgramId);
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Upgrades", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV2
        {...openSnackbarMessage}
        handleCloseFunction={handleCloseFunction}
        severity="error"
      />

      <NewClassDialog
        {...newClassDialogInfo}
        handleCloseNewClassDialog={handleCloseNewClassDialog}
        setValue={setValue2}
        trigger={trigger2}
        getValues={getValues2}
        handleGetBatchs={handleGetBatchs}
        isSubmitting={isSubmitting2}
        errors={errors2}
        handleSubmit={handleSubmit2}
        submitNewClassAssignment={submitNewClassAssignment}
        removeNewClass={removeNewClass}
      />

      <CommitDialog
        {...commitDialogInfo}
        handleCloseCommit={handleCloseCommit}
        handleRunCommit={handleRunCommit}
      />

      <Paper className={classes.paper}>
        
        <EnhancedTableToolbar
          handleFilter={handleFilter}
          handleOpenCommit={handleOpenCommit}
          rowCount={getApiResult.students.length}
          //activeStatus={activeStatus}
          //inActiveStatus={inActiveStatus}
          //deleteItems={deleteItems}
        />

        {filterOpen && (
          <SearchPanel register={register} searchType={searchType} />
        )}

        {getApiResult.programs && getApiResult.programs.length > 0 && (
          <AppBar position="static">
            <Tabs
              value={programIdTab}
              onChange={handleChangeProgramTab}
              aria-label="wrapped label tabs example"
            >
              {getApiResult.programs.map((row, index) => {
                return (
                  <Tab
                    key={row.programId}
                    value={row.programId}
                    label={row.programName}
                    wrapped
                    {...a11yProps(row.programId)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={getApiResult.students.length}
            />

            <TableBody>
              {getApiResult.students.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const _age = utils.site.calculateAge(new Date(row.dateOfBirth));
                //const statusIcon = getStatusIcon(row.status);
                const _updateConfirmStatus = row.updateConfirmStatus || 3;
                const _nextUniform = row.nextUniform ? row.nextUniform : false;
                const _beltSizeId = row.beltSizeId;
                const _img =
                  row.uploadFile && row.uploadFile !== ""
                    ? `${env.resourceBase}/media/uploadimages/${row.uploadFile}`
                    : "/assets/no-image.png";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    //aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.studentRegoTermReportId}
                    //selected={isItemSelected}
                  >
                    <TableCell
                      //component="th"
                      //padding="none"
                      id={labelId}
                      scope="row"
                      align="left"
                    >
                      <img width="50px" src={_img} alt="belt" />
                    </TableCell>

                    <TableCell align="left">{row.firstName}</TableCell>
                    <TableCell align="left">{_age}</TableCell>
                    <TableCell align="left">{row.beltName}</TableCell>

                    <TableCell align="left">
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                      >
                        <Select
                          native
                          inputProps={{
                            name: "nextUniform",
                            id: "nextUniform",
                          }}
                          value={_nextUniform ? "yes" : "no"}
                          onChange={(event) => {
                            handleChangeNewUniform(
                              event,
                              row.studentRegoId
                            );
                          }}
                        >
                          <option aria-label="Yes" value="yes">
                            Yes
                          </option>
                          <option aria-label="No" value="no">
                            No
                          </option>
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="left">
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                      >
                        <Select
                          native
                          inputProps={{
                            name: "updateConfirmStatus",
                            id: "updateConfirmStatus",
                          }}
                          value={_updateConfirmStatus}
                          onChange={(event) => {
                            handleChangeConfirm(
                              event,
                              row.studentRegoTermReportId
                            );
                          }}
                        >
                          {getApiResult.updateConfirmTypes.map((rowIn, index) => {
                            return (
                              <option
                                key={rowIn.value}
                                aria-label={rowIn.name}
                                value={rowIn.value}
                              >
                                {rowIn.name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="left">
                      <TextField
                        id="date"
                        //label="Birthday"
                        type="date"
                        variant="outlined"
                        size="small"
                        defaultValue={utils.site.formatYYYYMMDD(
                          new Date(row.uniformMeasuredDate)
                        )}
                        className={classes.textField}
                        InputLabelProps={
                          {
                            //shrink: true,
                          }
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      <FormControl
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                      >
                        <Select
                          native
                          inputProps={{
                            name: "beltSizeId",
                            id: "beltSizeId",
                          }}
                          value={_beltSizeId}
                          onChange={(event) => {
                            handleChangeBeltSize(event, row.studentRegoId);
                          }}
                        >
                          {getApiResult.beltSizes.map((rowIn2, index) => {
                            return (
                              <option
                                key={rowIn2.beltSizeId}
                                aria-label={rowIn2.length}
                                value={rowIn2.beltSizeId}
                              >
                                {rowIn2.length}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="left">
                      <Checkbox
                        checked={
                          row.nextBatchId && row.nextBatchId !== ""
                            ? true
                            : false
                        }
                        onClick={() => {
                          handleOpenNewClassDialog(
                            row.programId,
                            _updateConfirmStatus,
                            row.studentRegoTermReportId,
                            row.nextBatchId
                          );
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <EmailIcon fontSize = "small" style={{cursor: 'pointer'}} />
                    </TableCell>
                    <TableCell align="left">
                      {
                          row.actionCheck ?
                          <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} /> : <NotInterestedIcon fontSize="small" style={{color : "red"}} />
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //count={data.length}
          count={
            getApiResult.appManages && getApiResult.appManages.length > 0
              ? getApiResult.appManages[0].totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
}