import React from "react";
import env from "../../../env";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Link,
  useHistory,
  //useHistory,
  //useLocation,
  //useParams
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
//import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DeleteIcon from "@material-ui/icons/Delete";
//import AttachFileIcon from "@material-ui/icons/AttachFile";
//import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
//import DescriptionIcon from "@material-ui/icons/Description";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getIdToken } from "auth";
//import queryString from "query-string";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";
import utils from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(25% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(25% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    activeStudent,
    inActiveStudent,
    deleteStudents,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Students
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Student" arrow>
          <Button component={Link} to={`/dashboard/student/new`}>
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeStudent}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveStudent}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <Button onClick={deleteStudents}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const {
    register,
    studios,
    autoValue,
    inputAutoChange,
    programs,
    studentStatusList,
    searchType,
  } = props;

  return (
    <form method="POST" noValidate="" className={classes.root}>
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Name
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={150}
        />
      </FormControl>

      <Autocomplete
        id="studioId"
        //disabled={!checkCreateBatch}
        options={studios}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.studioName}
        getOptionSelected={(option, value) =>
          option.studioName === value.studioName
        }
        value={autoValue.studio || null}
        onChange={(event, newValue) => {
          inputAutoChange(
            event,
            "studioId",
            "studio",
            newValue ? newValue.studioId : null,
            newValue
          );
        }}
        renderOption={(option) => (
          <React.Fragment>{option.studioName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Studio Filter"
            variant="outlined"
            size="small"
            //error={!!errors.studioId}
            //helperText={errors?.studioId?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        id="programId"
        //disabled={!checkCreateBatch}
        options={programs}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.studioName}
        getOptionSelected={(option, value) =>
          option.studioName === value.studioName
        }
        value={autoValue.studio || null}
        onChange={(event, newValue) => {
          inputAutoChange(
            event,
            "programId",
            "program",
            newValue ? newValue.studioId : null,
            newValue
          );
        }}
        renderOption={(option) => (
          <React.Fragment>{option.studioName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Program Filter"
            variant="outlined"
            size="small"
            //error={!!errors.studioId}
            //helperText={errors?.studioId?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        id="status"
        //disabled={!checkCreateBatch}
        options={studentStatusList}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        value={autoValue.status || null}
        onChange={(event, newValue) => {
          inputAutoChange(
            event,
            "status",
            "status",
            newValue ? newValue.value : null,
            newValue
          );
        }}
        renderOption={(option) => (
          <React.Fragment>{option.name}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Status Filter"
            variant="outlined"
            size="small"
            //error={!!errors.studioId}
            //helperText={errors?.studioId?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const headCells = [
  {
    id: "Image",
    numeric: false,
    disablePadding: true,
    label: "Image",
    hasSort: false,
  },
  {
    id: "FirstName",
    numeric: false,
    disablePadding: false,
    label: "First",
    hasSort: true,
  },
  {
    id: "LastName",
    numeric: false,
    disablePadding: false,
    label: "Last",
    hasSort: true,
  },
  {
    id: "StudioName",
    numeric: false,
    disablePadding: false,
    label: "Studio",
    hasSort: true,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "StudentId",
    numeric: false,
    disablePadding: false,
    label: "Student Id",
    hasSort: false,
  },
  {
    id: "StartDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
    hasSort: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: false,
  },
  {
    id: "BletIcon",
    numeric: false,
    disablePadding: false,
    label: "Belt Icon",
    hasSort: false,
  },
  {
    id: "Attendance",
    numeric: false,
    disablePadding: false,
    label: "Attendance",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const getStatusIcon = (status) => {
  if (status === 1) {
    return (
      <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
    );
  } else if (status === 99) {
    return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
  }
  return null;
};

const schema = yup.object().shape({
  search: yup.string(),
  studioId: yup.string(),
  programId: yup.string(),
  status: yup.number(),
});

export function UserStudents(props) {
  const { userInfo } = props;
  const { dispatch } = useStore();
  const history = useHistory();
  const classes = useStyles();
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("FirstName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [trigger, setTrigger] = React.useState(0);
  const [getStudentsResult, setGetStudentsResult] = React.useState();
  const [autoValue, setAutoValue] = React.useState({
    studio: null,
    program: null,
    status: null,
  });

  const {
    getValues,
    register,
    setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { status: 1 },
  });

  const { status } = getValues();

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getStudentsResult.students.map((n) => n.studentId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (getStudentsResult.students && getStudentsResult.students.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChange = (event, studentId) => {
    const selectedIndex = selected.indexOf(studentId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, studentId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    //trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
    setBackdropOpen(true);
    setTrigger(trigger + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (getStudentsResult.students && getStudentsResult.students.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (getStudentsResult.students && getStudentsResult.students.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  async function activeStudent() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/activestudent", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedStudentIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        //window.location.reload();
        setSelected([]);
        setTrigger(trigger + 1);
      }

      //setBackdropOpen(false);
      // timerController.current = setTimeout(() => {
      //   setBackdropOpen(false);
      // }, 800);
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function inActiveStudent() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/inactivestudent", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedStudentIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        //window.location.reload();
        setSelected([]);
        setTrigger(trigger + 1);
      }

      //setBackdropOpen(false);
      // timerController.current = setTimeout(() => {
      //   setBackdropOpen(false);
      // }, 800);
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function deleteStudents() {

    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/deletestudents", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedStudentIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        //window.location.reload();
        setSelected([]);
        setTrigger(trigger + 1);
      }

      //setBackdropOpen(false);
      // timerController.current = setTimeout(() => {
      //   setBackdropOpen(false);
      // }, 800);
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Students", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(env.apiBase + "/api/student/getstudents", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            userInfo: userInfo,
          }),
          ...options,
        });

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          } else {
            throw new Error(`Response status: ${res.status}`);
          }
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results && data.results.getStudentsResult) {
          setGetStudentsResult(data.results.getStudentsResult);
          const _statusList =
            data.results.getStudentsResult.studentStatusTypeList;
          const _status = _statusList.find((x) => x.value === status);

          setAutoValue({
            ...autoValue,
            status: _status,
          });
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
          activeStudent={activeStudent}
          inActiveStudent={inActiveStudent}
          deleteStudents={deleteStudents}
        />
        {filterOpen && (
          <SearchPanel
            register={register}
            studios={getStudentsResult.studios}
            autoValue={autoValue}
            inputAutoChange={inputAutoChange}
            programs={[]}
            studentStatusList={getStudentsResult.studentStatusTypeList}
            searchType={searchType}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={getStudentsResult.students.length}
            />
            <TableBody>
              {getStudentsResult.students.map((row, index) => {
                
                const isItemSelected = isSelected(row.studentId);
                const labelId = `enhanced-table-checkbox-${index}`;
                const statusIcon = getStatusIcon(row.status);
                const _link =
                  row.parentCoreId !== null && row.parentCoreId !== ""
                    ? `/dashboard/student-detail/${row.studentId}`
                    : `/dashboard/student/${row.studentId}`;
                const _studentRego = row.studentRego;
                const displayStartDate = _studentRego
                  ? utils.site.formatDDMMYYYY(new Date(_studentRego.startDate))
                  : "-";
                const _img = _studentRego ? _studentRego.beltIcon && _studentRego.beltIcon !== "" ? `${env.resourceBase}/media/uploadimages/${_studentRego.beltIcon}` : "/assets/no-image.png" : "/assets/no-image.png";
                const _studentImg = row.profilePic && row.profilePic !== "" ? `${env.resourceBase}/media/uploadimages/${row.profilePic}` : "/assets/no-image.png";
                
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.studentId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={(event) => handleChange(event, row.studentId)}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <img
                        width="40px"
                        height="40px"
                        src={_studentImg}
                        alt="belt"
                        style={{ objectFit: "contain" }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Link to={_link}>{row.firstName}</Link>
                    </TableCell>
                    <TableCell align="left">{row.lastName}</TableCell>
                    <TableCell align="left">{row.studioName}</TableCell>
                    <TableCell align="left">
                      {" "}
                      {_studentRego ? _studentRego.programName : "-"}{" "}
                    </TableCell>
                    <TableCell align="left"> {row.displayStudentNo} </TableCell>
                    <TableCell align="left"> {displayStartDate} </TableCell>
                    <TableCell align="left">{statusIcon}</TableCell>
                    <TableCell align="left">
                      <img
                        width="40px"
                        height="40px"
                        src={_img}
                        alt="belt"
                        style={{ objectFit: "contain" }}
                      />
                    </TableCell>
                    <TableCell align="left"> - </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //count={data.length}
          count={
            getStudentsResult.students && getStudentsResult.students.length > 0
              ? getStudentsResult.students[0].totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
