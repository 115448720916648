import React from "react";
import { useParams, Link } from "react-router-dom";
import { ErrorMessage, Loading } from "components";
import { variables } from "../../../cssInJs";
import env from "../../../env";
import CheckIcon from "@material-ui/icons/Check";
import { useStore } from "../../../store";
import {
  //AppBar,
  //Tab,
  //Tabs,
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  //Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    //marginBottom: theme.spacing(2),
    //textAlign : 'center'
  },
  third_text: {
    marginTop: theme.spacing(3),
    color: variables.colorGreen,
  },
  status_text: {
    marginTop: theme.spacing(3),
  },
  active_text: {
    color: variables.colorBlue2,
    fontWeight: 700,
  },
  grid_container: {
    maxWidth: "600px",
    margin: "auto",
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
}));

export function Dashboard() {
  const classes = useStyles();
  const { dispatch } = useStore();
  const { studioId } = useParams();
  const previousController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getStudioDashResult, setGetStudioDashResult] = React.useState();

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Approved", link: null },
        ],
      },
    });
     
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/studio/getstudiodashboard",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              studioId: studioId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getStudioDashResult) {
          setGetStudioDashResult(data.results.getStudioDashResult);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center">
          Congratulations
        </Typography>
        <Typography align="center">
          Your studio request has been approved!
        </Typography>
        <Typography variant="h5" align="center" className={classes.third_text}>
          Approved Studio Details
        </Typography>
        <div style={{ maxWidth: "80px", margin: "auto" }}>
          <img
            width="100%"
            alt="logo"
            src={
              getStudioDashResult.studio.studioLogo
                ? `${env.resourceBase}/media/uploadimages/${getStudioDashResult.studio.studioLogo}`
                : "/assets/no-image.png"
            }
          />
        </div>
        <Grid container spacing={3} className={classes.grid_container}>
          <Grid item xs={6} container spacing={1}>
            <Grid item xs={6}>
              Studio Name:
            </Grid>
            <Grid item xs={6}>
              {getStudioDashResult.studio.studioName}
            </Grid>

            <Grid item xs={6}>
              Location:
            </Grid>
            <Grid item xs={6}>
              {getStudioDashResult.studio.location}
            </Grid>

            <Grid item xs={6}>
              Studio Admin Name:
            </Grid>
            <Grid item xs={6}>
              Adrian Adams
            </Grid>

            <Grid item xs={6}>
              Mobile Number:
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={6} container spacing={1}>
            <Grid item xs={6}>
              Studio Code:
            </Grid>
            <Grid item xs={6}>
              {getStudioDashResult.studio.studioCode}
            </Grid>

            <Grid item xs={6}>
              Request on:
            </Grid>
            <Grid item xs={6}>
              29/08/2024
            </Grid>

            <Grid item xs={6}>
              Approval on:
            </Grid>
            <Grid item xs={6}>
              03/09/2024
            </Grid>

            <Grid item xs={6}>
              StudioAdmin Email:
            </Grid>
            <Grid item xs={6}>
              team@jaba.com.au
            </Grid>

            <Grid item xs={6}>
              Date of Apply:
            </Grid>
            <Grid item xs={6}>
              29/08/2024
            </Grid>
          </Grid>
        </Grid>

        <hr className={classes.hr} />

        <Typography align="center">
          You can now begin your Studio Configuration Process required to set up
          your MASA Studio!. <br />
          Follow the set up steps detailed below
        </Typography>

        <Typography align="center" className={classes.status_text}>
          Status:{" "}
          <span className={classes.active_text}>
            {getStudioDashResult.studio.statusDisplayName}
          </span>
        </Typography>

        <Box
          mt={3}
          p={1}
          maxWidth={600}
          margin={"auto"}
          border={"1px solid black"}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage Programs
            </Grid>
            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage House Team
            </Grid>

            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage Belt Level
            </Grid>
            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage Awards
            </Grid>

            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage Settings
            </Grid>
            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage Terms
            </Grid>

            <Grid item xs={6} component={Link} to="/">
              <CheckIcon style={{ fontSize: 20, marginRight: "5px" }} />
              Manage Batches
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid item>Students</Grid>
            <Grid item>|</Grid>
            <Grid item>Instructors</Grid>
          </Grid>
        </Box>

        <Box maxWidth="360px" mt={5} margin={"auto"}>
          <img alt="logo" src="/assets/logos/logo.jpg" width="100%" />
        </Box>
      </Paper>
    </div>
  );
}
