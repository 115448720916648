import React from "react";
import { useStore } from "../../../store";
import { Link, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { variables } from "../../../cssInJs";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import SearchIcon from "@material-ui/icons/Search";
//import DeleteIcon from "@material-ui/icons/Delete";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
//import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import LensIcon from "@material-ui/icons/Lens";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ListItemIcon,
  //TextField,
  Backdrop,
  Dialog,
  List,
  ListItem,
  ListItemText,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    //margin: theme.spacing(1),
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    //studioId,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Awards
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, searchType } = props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={130}
        />
      </FormControl>
    </form>
  );
};

const SimpleDialog = (props) => {
  const { openDialog, handleClose } = props;
  const { open, contents } = openDialog;
  const classes = useDialogStyles();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">Criteria</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <List dense={true}>
          {contents &&
            contents.map((row, index) => {
              return (
                <ListItem>
                  <ListItemIcon>
                    <LensIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={row.title} />
                </ListItem>
              );
            })}
        </List>
      </MuiDialogContent>
    </Dialog>
  );
};

//EnhancedTableHead
const headCells = [
  {
    id: "AwardName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    hasSort: true,
  },
  {
    id: "EarningPoint",
    numeric: false,
    disablePadding: false,
    label: "Earning Point",
    hasSort: true,
  },
  {
    id: "Criteria",
    numeric: false,
    disablePadding: false,
    label: "Criteria",
    hasSort: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: true,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    order,
    orderBy,
    onRequestSort,
    //onSelectAllClick, 
    //numSelected,
    //rowCount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const schema = yup.object().shape({
  search: yup.string(),
});

export function Awards() {
  const { studioId } = useParams();
  const { dispatch } = useStore();
  const location = useLocation();
  const { awardtabtype } = queryString.parse(location.search);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getSetupAwardsResult, setGetSetupAwardsResult] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("AwardName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [trigger, setTrigger] = React.useState(0);
  const [currentTab, setCurrentTab] = React.useState(() => {
    if(awardtabtype && !isNaN(awardtabtype)){
      return parseInt(awardtabtype);
    }
    return 1;
  });
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState({
    open: false,
    contents: null,
  });

  const {
    getValues,
    register,
    setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    setOrder("asc");
    setOrderBy("AwardName");
    setValue("search", "");
    setBackdropOpen(true);
    setTrigger(trigger + 1);
  };

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getSetupAwardsResult.setupAwards.map((n) => n.setupAwardId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (
      getSetupAwardsResult.setupAwards &&
      getSetupAwardsResult.setupAwards.length > 0
    ) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getStatusIcon = (status) => {
    if (status === 1) {
      return (
        <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
      );
    } else if (status === 99) {
      return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
    }
    return null;
  };

  const openInfo = (setupAwardCriteria) => {
    openDialog.open = true;
    openDialog.contents = setupAwardCriteria;
    setOpenDialog({ ...openDialog });
  };

  const handleClose = () => {
    openDialog.open = false;
    setOpenDialog({ ...openDialog });
  };

  // const handleChange2 = (event, setupAwardId) => {
  //   const selectedIndex = selected.indexOf(setupAwardId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, setupAwardId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/award/getsetupawards", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            awardTabType: currentTab,
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            ...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getSetupAwardsResult) {
          setGetSetupAwardsResult(data.results.getSetupAwardsResult);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Awards", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SimpleDialog openDialog={openDialog} handleClose={handleClose} />
      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getSetupAwardsResult.awardTabList.map((awardTab, index) => {
              return (
                <Tab
                  key={awardTab.value}
                  value={awardTab.value}
                  label={awardTab.name}
                  wrapped
                  {...a11yProps(awardTab.value)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
        />

        {filterOpen && (
          <SearchPanel register={register} searchType={searchType} />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={getSetupAwardsResult.setupAwards.length}
            />

            <TableBody>
              {getSetupAwardsResult.setupAwards.map((row, index) => {
                const isItemSelected = isSelected(row.setupAwardId);
                const labelId = `enhanced-table-checkbox-${index}`;
                const statusIcon = getStatusIcon(row.status);
                
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.setupAwardId}
                    selected={isItemSelected}
                  >
                    
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={(event) =>
                          handleChange2(event, row.setupAwardId)
                        }
                      />
                    </TableCell> */}

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      //padding="none"
                    >
                      <Link to={`/dashboard/studio/${studioId}/award/${row.setupAwardId}?awardtabtype=${currentTab}`}>
                        {row.awardName}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.earningPoint}</TableCell>
                    <TableCell align="left">
                      <InfoIcon
                        className={classes.infoIcon}
                        fontSize="small"
                        onClick={() => {
                          openInfo(row.setupAwardCriteria);
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {statusIcon && (
                        <Tooltip title={"Active"} arrow placement="top">
                          {statusIcon}
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //count={data.length}
          count={
            getSetupAwardsResult.setupAwards &&
            getSetupAwardsResult.setupAwards.length > 0
              ? getSetupAwardsResult.setupAwards[0].totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}