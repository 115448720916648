import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
import * as yup from "yup";
//import { useTheme } from "@material-ui/core/styles";
//import utils from "utils";
import DatePicker from "react-datepicker";
import { StudentView } from "./studentView";
//import { StudentBottom } from "./studentBottom";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import AddIcon from "@material-ui/icons/Add";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
//import CircularProgress from "@material-ui/core/CircularProgress";
//import DeleteIcon from "@material-ui/icons/Delete";
import DateRangeIcon from "@material-ui/icons/DateRange";

import {
  Link,
  //useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  Toolbar,
  lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  FormControlLabel,
  FormHelperText,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 175px)",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom : theme.spacing(4),
    height: "calc(100vh - 175px)",
    overflowY : "auto"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useEditView = makeStyles((theme) => ({
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    //margin: theme.spacing(1),
    //width: `calc(50% - ${theme.spacing(2)}px)`,
    width: `-webkit-fill-available`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: 1
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },

  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },

  title_criteria: {
    flex: "1 1 100%",
  },

  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },

  paperi: {
    padding: theme.spacing(1),
  },
}));

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const schema = yup.object().shape({
  evaluationDate: yup.date().required("Evaluation Date is required."),
  category: yup.number().required("Category is required."),

  physicalSkill1Point: yup.number().required("Physical Skill is required."),
  physicalSkill1Commnet: yup.string().nullable(true),
  physicalSkill2Point: yup.number().required("Physical Skill is required."),
  physicalSkill2Commnet: yup.string().nullable(true),
  physicalSkill3Point: yup.number().required("Physical Skill is required."),
  physicalSkill3Commnet: yup.string().nullable(true),

  intellectualSkill1Point: yup.number().required("Point is required."),
  intellectualSkill1Commnet: yup.string().nullable(true),
  intellectualSkill2Point: yup.number().required("Point is required."),
  intellectualSkill2Commnet: yup.string().nullable(true),
  intellectualSkill3Point: yup.number().required("Point is required."),
  intellectualSkill3Commnet: yup.string().nullable(true),

  emotionalSkill1Point: yup.number().required("Point is required."),
  emotionalSkill1Commnet: yup.string().nullable(true),
  emotionalSkill2Point: yup.number().required("Point is required."),
  emotionalSkill2Commnet: yup.string().nullable(true),
  emotionalSkill3Point: yup.number().required("Point is required."),
  emotionalSkill3Commnet: yup.string().nullable(true),

  socialSkill1Point: yup.number().required("Point is required."),
  socialSkill1Commnet: yup.string().nullable(true),
  socialSkill2Point: yup.number().required("Point is required."),
  socialSkill2Commnet: yup.string().nullable(true),
  socialSkill3Point: yup.number().required("Point is required."),
  socialSkill3Commnet: yup.string().nullable(true),

  finalComments: yup.string().nullable(true),
});

const EvaluationEditView = (props) => {
  const {
    //student,
    //parent1,
    //parent2,
    //accountHolderInfo,
    //emergencyContactInfo
    evaCateTypes,
  } = props;

  const classes = useEditView();
  const [selected, setSelected] = React.useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    watch,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { category: 1 },
  });

  const { evaluationDate, category } = getValues();

  const [autoValue, setAutoValue] = React.useState({
    category: evaCateTypes[0],
  });

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
  };

  return (
    <>
      <Typography className={classes.marginDiv}>
        <DateRangeIcon /> Evaluation
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.formRoot}
        //onSubmit={handleSubmit(submit)}
      >
        <div className={classes.datePicker}>
          <Typography variant="caption"> Evaluation Date* </Typography>
          <DatePicker
            selected={evaluationDate}
            onChange={(date) => {
              setValue("evaluationDate", date);
              trigger("evaluationDate");
            }}
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
          {!!errors.evaluationDate && (
            <FormHelperText error id="startDate-error">
              {errors?.evaluationDate?.message}
            </FormHelperText>
          )}
        </div>

        <Autocomplete
          id="category"
          //disabled={!checkCreateBatch}
          options={evaCateTypes}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
          value={autoValue.category || null}
          onChange={(event, newValue) => {
            inputAutoChange(
              event,
              "category",
              "category",
              newValue ? newValue.value : null,
              newValue
            );
          }}
          renderOption={(option) => (
            <React.Fragment>{option.name}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category*"
              variant="outlined"
              size="small"
              error={!!errors.category}
              helperText={errors?.category?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        {category && (
          <Grid
            container
            spacing={3}
            style={{ paddingLeft: "8px", paddingRight: "8" }}
          >
            <Grid item xs={6}>
              <Paper elevation={1} className={classes.paperi}>
                <Typography>Physical Skills</Typography>
                <Typography variant={"caption"}>
                  {" "}
                  {/* Performs 10 Dragon push ups from knees */}
                  {" "}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.physicalSkill1Point}
                  helperText={errors?.physicalSkill1Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("physicalSkill1Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.physicalSkill1Commnet}
                  helperText={errors?.physicalSkill1Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("physicalSkill1Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* Balance on one leg for 5 seconds{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.physicalSkill2Point}
                  helperText={errors?.physicalSkill2Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("physicalSkill2Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.physicalSkill2Commnet}
                  helperText={errors?.physicalSkill2Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("physicalSkill2Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* 5 Star jumps - using 1 Sir, 2 Sir …. Hands high, feet wide?{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.physicalSkill3Point}
                  helperText={errors?.physicalSkill3Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("physicalSkill3Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.physicalSkill3Commnet}
                  helperText={errors?.physicalSkill3Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("physicalSkill3Commnet")}
                />
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={1} className={classes.paperi}>
                <Typography> Intellectual Skills </Typography>
                <Typography variant={"caption"}>
                  {" "}
                  {/* Performs 10 Dragon push ups from knees{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.intellectualSkill1Point}
                  helperText={errors?.intellectualSkill1Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("intellectualSkill1Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.intellectualSkill1Commnet}
                  helperText={errors?.intellectualSkill1Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("intellectualSkill1Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  Balance on one leg for 5 seconds{" "}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.intellectualSkill2Point}
                  helperText={errors?.intellectualSkill2Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("intellectualSkill2Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.intellectualSkill2Commnet}
                  helperText={errors?.intellectualSkill2Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("intellectualSkill2Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* 5 Star jumps - using 1 Sir, 2 Sir …. Hands high, feet wide?{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.intellectualSkill3Point}
                  helperText={errors?.intellectualSkill3Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("intellectualSkill3Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.intellectualSkill3Commnet}
                  helperText={errors?.intellectualSkill3Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("intellectualSkill3Commnet")}
                />
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={1} className={classes.paperi}>
                <Typography> Emotional Skills </Typography>
                <Typography variant={"caption"}>
                  {" "}
                  {/* Performs 10 Dragon push ups from knees{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.intellectualSkill1Point}
                  helperText={errors?.intellectualSkill1Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("intellectualSkill1Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.intellectualSkill1Commnet}
                  helperText={errors?.intellectualSkill1Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("intellectualSkill1Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* Balance on one leg for 5 seconds{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.intellectualSkill2Point}
                  helperText={errors?.intellectualSkill2Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("intellectualSkill2Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.intellectualSkill2Commnet}
                  helperText={errors?.intellectualSkill2Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("intellectualSkill2Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* 5 Star jumps - using 1 Sir, 2 Sir …. Hands high, feet wide?{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.intellectualSkill3Point}
                  helperText={errors?.intellectualSkill3Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("intellectualSkill3Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.intellectualSkill3Commnet}
                  helperText={errors?.intellectualSkill3Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("intellectualSkill3Commnet")}
                />
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper elevation={1} className={classes.paperi}>
                <Typography> Social Skills </Typography>
                <Typography variant={"caption"}>
                  {" "}
                  {/* Performs 10 Dragon push ups from knees{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.socialSkill1Point}
                  helperText={errors?.socialSkill1Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("socialSkill1Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.socialSkill1Commnet}
                  helperText={errors?.socialSkill1Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("socialSkill1Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* Balance on one leg for 5 seconds{" "} */}
                </Typography>
                
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.socialSkill2Point}
                  helperText={errors?.socialSkill2Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("socialSkill2Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.socialSkill2Commnet}
                  helperText={errors?.socialSkill2Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("socialSkill2Commnet")}
                />

                <Typography variant={"caption"}>
                  {" "}
                  {/* 5 Star jumps - using 1 Sir, 2 Sir …. Hands high, feet wide?{" "} */}
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Point*"
                  variant="outlined"
                  error={!!errors.socialSkill3Point}
                  helperText={errors?.socialSkill3Point?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("socialSkill3Point")}
                />
                <TextField
                  //id="outlined-basic"
                  label="Commnet"
                  variant="outlined"
                  error={!!errors.socialSkill3Commnet}
                  helperText={errors?.socialSkill3Commnet?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={2}
                  {...register("socialSkill3Commnet")}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={1} className={classes.paperi}>
                <TextField
                  //id="outlined-basic"
                  label="Final Comments"
                  variant="outlined"
                  error={!!errors.finalComments}
                  helperText={errors?.finalComments?.message}
                  size="small"
                  className={classes.textField}
                  multiline
                  minRows={3}
                  {...register("finalComments")}
                />
              </Paper>
            </Grid>
          </Grid>
        )}

        <div
          className={classes.buttonContainer}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            component={Link}
            size="small"
            to={`/dashboard/property-metrics/setup-tshirts`}
            disabled={isSubmitting}
            //color="primary"
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>

      </form>
    </>
  );
};

export function StudentEvaluation() {
  const classes = useStyles();
  const { studentId } = useParams();
  //const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getstudentDetailRe, setGetstudentDetailRe] = React.useState();
  const previousController = React.useRef(null);

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/student/getstudentevaluation",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //setupAwardId: setupAwardId,
              studentId: studentId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getstudentDetailRe) {
          //const _evaCateTypes = data.results.getSetupHouseTeamRe.evaCateTypes;
          setGetstudentDetailRe({
            ...data.results.getstudentDetailRe,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar tab={"evaluations"} studentId={studentId} />
        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo {...getstudentDetailRe} />
            </Grid>

            <Grid item xs={9}>
              <Box className={classes.rightGridBox}>
                <EvaluationEditView {...getstudentDetailRe} />
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* <StudentBottom /> */}
      </Paper>
    </div>
  );
}