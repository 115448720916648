import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  //Typography,
  //Box,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
//import { Programs } from "./programs";
import {
  //useParams,
  Link,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "1px",
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
    },
  },
  appBar: {
    backgroundColor: variables.colorLightGray,
    color: variables.fontColor,
  },
}));

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

function createTab(id, label, link = null) {
  return { id, label, link };
}

const tabItems = [
  createTab("upgrades", "Upgrades", "upgrades"),
  createTab("certificates", "Certificates", "certificates"),
  createTab("houseTeamReports", "House Team Reports", "house-team-reports"),
  createTab("beltOrderReports", "Belt Order Reports", "belt-order-reports"),
  createTab("studioReports", "Studio Reports", "studio-reports"),
  createTab(
    "nonPromotionReports",
    "Non-Promotion Reports",
    "non-promotion-reports"
  ),
];

export function StudioMenuReports({ studioId, pathname }) {
  const classes = useStyles();
  const [value, setValue] = React.useState("upgrades");

  React.useEffect(() => {
    let result = "upgrades";
    if (
      pathname.includes("/program/new") ||
      pathname.includes("/programs") ||
      pathname.includes("/program/")
    ) {
      result = "programs";
    } else if (pathname.includes("/certificates")) {
      result = "certificates";
    }
    else if (pathname.includes("/non-promotion-reports")) {
      result = "nonPromotionReports";
    }
    else if (pathname.includes("/house-team-reports")) {
      result = "houseTeamReports";
    }
    else if (pathname.includes("/studio-reports")) {
      result = "studioReports";
    }
    setValue(result);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          aria-label="wrapped label tabs example"
          //onChange={handleChange}
          // variant="scrollable"
          // scrollButtons="on"
        >
          {tabItems.map((row, index) => {
            const link = row.link ? row.link : row.id;
            return (
              <Tab
                key={row.id}
                value={row.id}
                label={row.label}
                wrapped
                component={Link}
                to={`/dashboard/studio/${studioId}/${link}`}
                className={classes.tab}
                {...a11yProps(row.id)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}
