import React from "react";
import env from "../../../env";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LensIcon from "@material-ui/icons/Lens";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import queryString from "query-string";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  //FormHelperText,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //Backdrop,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title_criteria: {
    flex: "1 1 100%",
  },
  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },
}));

const useGVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const GetViewMode = (props) => {
  const classes = useGVModeStyles();
  const { getSetupAwardResult, changeViewMode, awardtabtype } = props;
  const { setupAward } = getSetupAwardResult;
  const displayDateUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(setupAward.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Awards
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/property-metrics/setup-awards?awardtabtype=${awardtabtype}`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.awardName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Award Type:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.displayAwardType}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Earning Point:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.earningPoint}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            className={classes.viewTitle}
            //variant="caption"
          >
            Criteria
          </Typography>
          <List dense={true} className={classes.list}>
            {setupAward.setupAwardCriteria &&
              setupAward.setupAwardCriteria.map((row, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <LensIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={row.title} />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.awardName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayDateUpdated}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>
            {setupAward.modifiedBy}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const schema = yup.object().shape({
  awardName: yup.string().required("Award Name is required."),
  awardType: yup.number().required("Award Type is required."),
  earningPoint: yup.number().required("Earning Point is required."),
  seq: yup.number().required("Seq is required."),
  criteria: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Title is required."),
      attachment: yup.string().nullable(true),
      criterionId: yup.string().nullable(true),
    })
  ),
});

export function SetupAward() {
  const { setupAwardId } = useParams();
  const { dispatch } = useStore();
  const classes = useStyles();
  const location = useLocation();
  const { awardtabtype } = queryString.parse(location.search);
  const history = useHistory();
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getSetupAwardResult, setGetSetupAwardResult] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  //const [currentTab, setCurrentTab] = React.useState();
  const [autoValue, setAutoValue] = React.useState({
    awardType: null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   criteria: [
    //     { criterionTitle: "abc1", bbc: "bbc1" },
    //     { criterionTitle: "abc2", bbc: "bbc2" },
    //   ],
    // },
  });

  const { criteria } = getValues();

  async function submit(dataModel) {
    setServerErrors([]);
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/award/savesetupaward", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          setupAwardId: setupAwardId,
          awardTabType: awardtabtype ? awardtabtype : 1,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/property-metrics/setup-awards`);
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  }

  const { fields, append, remove } = useFieldArray({
    name: "criteria",
    control,
  });

  const addCriteria = () => {
    append({ title: "", attachment: "",  criterionId : null});
  };

  const removeCriteria = (index) => {
    remove(index);
  };

  const inputAutoChange = (event, newValue, id, value) => {
    setValue(id, value);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id]: newValue,
    });
  };

  const changeViewMode = () => {
    getSetupAwardResult.editMode = false;
    setGetSetupAwardResult({ ...getSetupAwardResult });
  };

  React.useEffect(() => {
    
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Awards", link: "/dashboard/property-metrics/setup-awards" },
          { title: "Details", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/award/getsetupaward", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            setupAwardId: setupAwardId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getSetupAwardResult) {
          let editMode = false;
          let newBatch = true;

          const _setupAward = data.results.getSetupAwardResult.setupAward;

          

          const _awardTypeList =
            (awardtabtype || "1") === "2"
              ? data.results.getSetupAwardResult.awardTypeList.filter(
                  (x) => x.value === 1
                )
              : data.results.getSetupAwardResult.awardTypeList.filter(
                  (x) => x.value !== 1
                );

          if (_setupAward) {
            const _setupAwardCriteria = _setupAward.setupAwardCriteria; 
            editMode = true;
            newBatch = false;
            
            reset({
              awardName: _setupAward.awardName,
              awardType: _setupAward.awardType,
              earningPoint: _setupAward.earningPoint,
              seq : _setupAward.seq,
              criteria: _setupAwardCriteria,
            });

            //append({ criterionTitle: "", criterionAttachment: "", criterionId : null });

            // _setupAwardCriteria.forEach(el => {
            //   append({
            //     criterionTitle : el.title,
            //     criterionAttachment : el.criterionAttachment,
            //     criterionId : el.criterionId
            //   });
            // });

            autoValue.awardType = _awardTypeList.find(
              (x) => x.value === _setupAward.awardType
            );
            setAutoValue({ ...autoValue });
          }
          setGetSetupAwardResult({
            ...data.results.getSetupAwardResult,
            editMode,
            newBatch,
            awardTypeList: _awardTypeList,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>

      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={awardtabtype}
            //onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getSetupAwardResult.awardTabList.map((awardTab, index) => {
              return (
                <Tab
                  key={awardTab.value}
                  value={awardTab.value + ""}
                  label={awardTab.name}
                  {...a11yProps(awardTab.value)}
                  component={Link}
                  to={`/dashboard/property-metrics/setup-awards?awardtabtype=${awardTab.value}`}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {getSetupAwardResult && getSetupAwardResult.editMode === true ? (
          <GetViewMode
            getSetupAwardResult={getSetupAwardResult}
            changeViewMode={changeViewMode}
            awardtabtype={awardtabtype}
          />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Award
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              <TextField
                //id="outlined-basic"
                label="Name*"
                variant="outlined"
                error={!!errors.awardName}
                helperText={errors?.awardName?.message}
                size="small"
                className={classes.textField}
                {...register("awardName")}
              />

              <Autocomplete
                id="awardType"
                //disabled={!checkCreateBatch}
                options={getSetupAwardResult.awardTypeList}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={autoValue.awardType || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    newValue,
                    "awardType",
                    newValue ? newValue.value : null
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Award Type*"
                    variant="outlined"
                    size="small"
                    error={!!errors.awardType}
                    helperText={errors?.awardType?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                //id="outlined-basic"
                label="Earning Point*"
                variant="outlined"
                error={!!errors.earningPoint}
                helperText={errors?.earningPoint?.message}
                size="small"
                className={classes.textField}
                type="number"
                {...register("earningPoint")}
              />

              <TextField
                //id="outlined-basic"
                label="Seq*"
                variant="outlined"
                error={!!errors.seq}
                helperText={errors?.seq?.message}
                size="small"
                className={classes.textField}
                type="number"
                {...register("seq")}
              />

              <Box className={classes.box_criteria}>
                <Typography
                  className={clsx(classes.marginDiv, classes.title_criteria)}
                >
                  Criteria (Title, Attachment : Allowed jpg, pngonly)
                </Typography>
                <ButtonGroup
                  className={classes.button_criteria}
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Tooltip title="Create Batch" arrow>
                    <Button onClick={addCriteria}>
                      <AddIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Box>

              {fields.map((field, index) => {
                const _attachment = criteria[index].attachment;
                return (
                  <Box key={field.id} className={classes.box_field}>
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex" }}>
                        <TextField
                          //id="outlined-basic"
                          label="Title*"
                          variant="outlined"
                          error={!!errors.criteria?.[index]?.title}
                          helperText={errors?.criteria?.[index]?.title?.message}
                          size="small"
                          className={classes.textField_field}
                          {...register(`criteria.${index}.title`)}
                        />

                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                          className={classes.buttonGroup_field}
                        >
                          <Tooltip title="Remove Criterion" arrow>
                            <Button
                              onClick={() => {
                                removeCriteria(index);
                              }}
                            >
                              <RemoveCircleOutlineIcon fontSize="small" />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </div>

                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6}>
                          <FilePond
                            className={classes.filePond_field}
                            allowMultiple={false}
                            maxFiles={1}
                            name="signatureImage"
                            maxFileSize={"10MB"}
                            allowDrop={false}
                            allowReorder={true}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            acceptedFileTypes={["image/*"]}
                            server={{
                              process: {
                                url: env.apiBase + "/api/file/uploadimage",
                                onload: (res) => {
                                  setValue(`criteria.${index}.attachment`, res);
                                  trigger(`criteria.${index}.attachment`);
                                },
                              },
                              fetch: null,
                              revert: null,
                            }}
                            onremovefile={() => {
                              setValue(`criteria.${index}.attachment`, "");
                              trigger(`criteria.${index}.attachment`);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ maxHeight: "100px", height: "200px" }}>
                            <img
                              src={`${
                                _attachment && _attachment !== ""
                                  ? `${env.resourceBase}/media/uploadimages/${_attachment}`
                                  : "/assets/no-image.png"
                              }`}
                              width="auto"
                              height="100%"
                              alt="logo"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                );
              })}

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/property-metrics/setup-awards`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}