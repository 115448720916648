import React from "react";
import clsx from "clsx";
//import { LoadingCircle } from "../../icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loading: {
    minHeight: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "0",
    transition: "all 1s",
  },
  loaded: {
    opacity: "1",
  },
}));

export function Loading({ children = undefined }) {
  const [loaded, setLoaded] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={clsx(classes.loading, {
        [classes.loaded]: loaded,
      })}
    >
      <div>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
        {children}
      </div>
    </div>
  );
}