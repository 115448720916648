import React from "react";
import env from "../../../env";
import { useForm } from "react-hook-form";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import { yupResolver } from "@hookform/resolvers/yup";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import clsx from "clsx";
import * as yup from "yup";
//import { useTheme } from "@material-ui/core/styles";
import utils from "utils";
import { StudentView } from "./studentView";
import { StudentBottom } from "./studentBottom";
import RefreshIcon from "@material-ui/icons/Refresh";
//import AddIcon from "@material-ui/icons/Add";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import SearchIcon from "@material-ui/icons/Search";
//import InfoIcon from "@material-ui/icons/Info";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
//import LensIcon from "@material-ui/icons/Lens";
//import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import DatePicker from "react-datepicker";

import {
  //Link,
  //useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //FormHelperText,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 175px)",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    //width: `calc(50% - ${theme.spacing(2)}px)`,
    width: "-webkit-fill-available",
    display: "inline-flex",
    //margin: theme.spacing(1),
  },
}));

const useRightStyles = makeStyles((theme) => ({}));

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    refresh
  } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Attendence
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create" arrow>
          <Button onClick={refresh}>
          <RefreshIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
      
    </Toolbar>
  );
};

const headCells = [
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Class Date",
    hasSort: true,
  },
  {
    id: "Day",
    numeric: false,
    disablePadding: false,
    label: "Day",
    hasSort: false,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "Time",
    numeric: false,
    disablePadding: false,
    label: "Time",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const RightItemView = (props) => {
  const classes = useRightStyles();
  const {
    studentId,
    page,
    rowsPerPage,
    order,
    orderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    filterOpen,
    setValue,
    trigger,
    getValues,
    terms,
    refresh,
    historyies
  } = props;

  return (
    <>
      <EnhancedTableToolbar
        studentId={studentId}
        refresh={refresh}
      />

      {filterOpen && (
        <SearchPanel
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          terms={terms}
          //handleChangeTerm={handleChangeTerm}
        />
      )}

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {(historyies || []).map((historyRow, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const displayDate = utils.site.formatDDMMYYYY(
                new Date(historyRow.date)
              );

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={historyRow.studetnClassHistoryId}
                >
                  
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    //padding="none"
                  >
                    {displayDate}
                  </TableCell>
                  
                  <TableCell align="left">{historyRow.displayDay}</TableCell>
                  <TableCell align="left">{historyRow.programName}</TableCell>
                  <TableCell align="left">{`${historyRow.startTime} - ${historyRow.endTime}`}</TableCell>

                </TableRow>
              );
            })}
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={
          historyies && historyies.length > 0 ? historyies[0].totalCount : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const {
    terms,
    setValue,
    trigger,
    getValues,
    handleChangeTerm,
  } = props;
  const { termId } = getValues();
  return (
    <form method="POST" noValidate="" className={classes.root}>

      <Autocomplete
        id="termId"
        options={terms || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.termName}
        value={(terms || []).find((x) => x.termId === termId) || null}
        getOptionSelected={(option, value) => option.termId === value.termId}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue("termId", newValue.termId);
            trigger("termId");
            handleChangeTerm();
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.termName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Term"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const schema = yup.object().shape({
  termId: yup.string().required("Date Format is required."),
});

export function StudentAttendance() {
  const classes = useStyles();
  const { studentId } = useParams();
  const { dispatch } = useStore();
  const previousController = React.useRef();
  //const timerController = React.useRef();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: true,
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "Date",
  });
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  const {
    //register,
    getValues,
    setValue,
    trigger,
    reset,
    //clearErrors,
    //handleSubmit,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  // const apiCall = async (
  //   url,
  //   apiData,
  //   returnFunction = null
  //   //retrunErrorFunction = null
  // ) => {
  //   setLoading(2);

  //   if (timerController.current) {
  //     clearTimeout(timerController.current);
  //   }

  //   if (previousController.current) {
  //     previousController.current.abort();
  //   }

  //   const abortController = new AbortController();
  //   const signal = abortController.signal;
  //   previousController.current = abortController;

  //   try {
  //     const options = { headers: { "Content-Type": "application/json" } };
  //     const res = await fetch(env.apiBase + url, {
  //       method: "post",
  //       signal: signal,
  //       body: JSON.stringify({
  //         ...apiData,
  //       }),
  //       ...options,
  //     });

  //     if (!res.ok) {
  //       throw new Error(`Response status: ${res.status}`);
  //     }

  //     const data = await res.json();

  //     if (data.errors && data.errors.length > 0) {
  //       setErrorResult({
  //         type: "snackbar",
  //         errors: data.errors,
  //       });
  //       setLoading(0);
  //       return;
  //     }

  //     if (returnFunction) {
  //       returnFunction(data.results);
  //       timerController.current = setTimeout(() => {
  //         setLoading(0);
  //       }, 800);
  //     } else {
  //       setApiTrigger(apiTrigger + 1);
  //     }
  //   } catch (error) {
  //     const _errors = [];
  //     _errors.push("error : " + error.message);
  //     setErrorResult({
  //       type: "server",
  //       errors: _errors,
  //     });
  //     setLoading(0);
  //     return;
  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";

    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const refresh = () => {
    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  }

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/student/getstudentattendance",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              ...funtionInfo,
              studentId: studentId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { selectedTermId } = data.results;

          if(selectedTermId){
            reset({
              termId : selectedTermId
            });
          }

          setGetApiResult({
            ...data.results
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Students", link: "/dashboard/students" },
          { title: "Details", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar tab={"attendence"} studentId={studentId} />

        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo {...getApiResult} />
            </Grid>
            <Grid item xs={9}>
              <Box className={classes.rightGridBox}>
                <RightItemView
                  {...funtionInfo}
                  {...getApiResult} 
                  studentId={studentId} 
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleRequestSort={handleRequestSort}
                  getValues = {getValues}
                  setValue = {setValue}
                  trigger = {trigger}
                  refresh = {refresh}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <StudentBottom />
      </Paper>
    </div>
  );
}
