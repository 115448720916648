import React from "react";
import env from "../../../env";
import { useStore } from "../../../store";
import { Loading, ErrorMessage } from "components";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  //AppBar,
  //Tab,
  //Tabs,
  //Toolbar,
  //lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  Dialog,
  //MuiDialogTitle,
  //Tooltip,
  //ButtonGroup,
  //Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
  infoIcon: {
    cursor: "pointer",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const useSPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
}));

//EnhancedTableHead
const headCells = [
  {
    id: "HouseTeamName",
    numeric: false,
    disablePadding: false,
    label: "House Team Name",
    hasSort: true,
  },
  {
    id: "HouseTeamLogo",
    numeric: false,
    disablePadding: false,
    label: "Logo",
    hasSort: false,
  },
  {
    id: "Description",
    numeric: false,
    disablePadding: false,
    label: "Info",
    hasSort: false,
  },
];

function EnhancedTableHead(props) {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    //numSelected,
    //rowCount,
    onRequestSort,
  } = props;
  const classes = useETHeadStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

function SimpleDialog(props) {
  const { openDialog, handleClose, currentInfo } = props;
  const classes = useDialogStyles();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={openDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{currentInfo.houseTeamName}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        <Typography gutterBottom>{currentInfo.description}</Typography>
      </MuiDialogContent>
    </Dialog>
  );
}

const SearchPanel = (props) => {
  const classes = useSPanelStyles();
  const { register, searchType } = props;
  return (
    <form method="POST" noValidate="" className={classes.root}>
      <FormControl
        //className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by House Team Name
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={240}
        />
      </FormControl>
    </form>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
});

export function HouseTeams(props) {
  const classes = useStyles();
  const { userInfo } = props;
  const { dispatch } = useStore();
  const { studioId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("HouseTeamName");
  const [trigger, setTrigger] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentInfo, setCurrentInfo] = React.useState();
  const [getHouseTeamsResult, setGetHouseTeamsResult] = React.useState();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const {
    getValues,
    register,
    //setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (
      getHouseTeamsResult &&
      getHouseTeamsResult.houseTeams &&
      getHouseTeamsResult.houseTeams.length > 0
    ) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const openInfo = (houseTeam) => {
    setOpenDialog(true);
    setCurrentInfo(houseTeam);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/houseteam/gethouseteams", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            studioId: studioId,
            order: order,
            orderBy: orderBy,
            userInfo: userInfo,
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getHouseTeamsResult) {
          setGetHouseTeamsResult(data.results.getHouseTeamsResult);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "House Teams", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {currentInfo && (
        <SimpleDialog
          openDialog={openDialog}
          handleClose={handleClose}
          currentInfo={currentInfo}
        />
      )}

      <Paper className={classes.paper}>
        <SearchPanel register={register} searchType={searchType} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {getHouseTeamsResult.houseTeams.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                const _img =
                  row.houseTeamLogo && row.houseTeamLogo !== ""
                    ? `${env.resourceBase}/media/uploadimages/${row.houseTeamLogo}`
                    : "/assets/no-image.png";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.houseTeamId}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                      //padding="none"
                    >
                      {row.houseTeamName}
                    </TableCell>
                    <TableCell align="left">
                      <img width="40px" src={_img} alt="belt" />
                    </TableCell>
                    <TableCell align="left">
                      <InfoIcon
                        className={classes.infoIcon}
                        onClick={() => {
                          openInfo(row);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
