import React from "react";
//import env from "../../../env";
import { variables } from "../../../cssInJs";
//import * as yup from "yup";
import {
  Link,
  //useHistory,
  //useLocation,
  //useParams,
} from "react-router-dom";
import {
  AppBar,
  Tab,
  Tabs,
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  //Typography,
  //Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  //Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: variables.colorLightGray,
    color: variables.fontColor,
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
    },
    "&:focus": {
      color: "inherit",
      //opacity: '0.7'
    },
  },
}));

const createTab = (value, label, link) => {
  return { value, label, link };
};

const tabs = [
  createTab("student", "Student", "student-detail"),
  createTab("evaluations", "Evaluations", "evaluations"),
  createTab("rego2", "Rego2", "rego"),
  createTab("classes", "Classes", "assign-classes"),
  createTab("awards", "Awards", "award-list"),
  createTab("promotions", "Promotions", "promotion-list"),
  createTab("attendence", "Attendence", "attendance"),
];

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

export function StudentAppBar(props) {
  const { tab, studentId } = props;
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Tabs value={tab} aria-label="wrapped label tabs example">
        {tabs.map((row, index) => {
          const _link = `/dashboard/${row.link}/${studentId}`;
          return (
            <Tab
              key={row.value}
              value={row.value}
              label={row.label}
              component={Link}
              to={_link}
              className={classes.tab}
              wrapped
              {...a11yProps(row.value)}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
}
