import React from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import DashboardLayoutRoute from "layout/dashboardLayout";
import DashboardLayoutForUserRoute from "layout/dashboardLayoutForUser";

import { Dashboard } from "site/members/dashboard/dashbaord";
import { Studios } from "site/members/studios/studios";
import { ErrorMessage, Loading } from "components";
//import EnhancedTable from "site/members/studios/studios_test";
import { StudioSection } from "site/members/studio/studio";
import { StudioEdit } from "site/members/studios/studioEdit";
import { SetupAwards } from "site/members/setup/setupAwards";
import { SetupAward } from "site/members/setup/setupAward";

import { SetupPrograms } from "site/members/setup/setupPrograms";
import { SetupProgram } from "site/members/setup/setupProgram";

import { SetupBeltSizes } from "site/members/setup/setupBeltSizes";
import { SetupBeltSize } from "site/members/setup/setupBeltSize";
import { SetupUniforms } from "site/members/setup/setupUniforms";
import { SetupUniform } from "site/members/setup/setupUniform";
import { SetupGloves } from "site/members/setup/setupGloves";
import { SetupGlove } from "site/members/setup/setupGlove";
import { SetupTshirts } from "site/members/setup/setupTshirts";
import { SetupTshirt } from "site/members/setup/setupTshirt";
import { SetupMedicals } from "site/members/setup/setupMedicals";
import { SetupMedical } from "site/members/setup/setupMedical";
import { SetupHowHears } from "site/members/setup/setupHowHears";
import { SetupHowHear } from "site/members/setup/setupHowHear";
import { SetupHouseTeams } from "site/members/setup/setupHouseTeams";
import { SetupHouseTeam } from "site/members/setup/setupHouseTeam";
import { SetupBeltLevels } from "site/members/setup/setupBeltLevels";
import { SetupBeltLevel } from "site/members/setup/setupBeltLevel";

import { UserStudents } from "site/members/user/userStudents";
import { UserStudent } from "site/members/user/userStudent";
import { UserParent } from "site/members/user/userParent";
import { UserInstructors } from "site/members/user/userInstructors";
import { UserInstructor } from "site/members/user/userInstructor";

import { StudentDetail } from "site/members/student/studentDetail";
import { StudentEvaluations } from "site/members/student/studentEvaluations";
import { StudentEvaluation } from "site/members/student/studentEvaluation";
import { StudentRego } from "site/members/student/studentRego";
import { StudentAssignClasses } from "site/members/student/studentAssignClasses";
import { StudentAssignClass } from "site/members/student/studentAssignClass";
import { StudentAwards } from "site/members/student/studentAwards";
import { StudentPromotions } from "site/members/student/studentPromotions";
import { StudentAttendance } from "site/members/student/studentAttendance";

import { AppManTerms } from "site/members/appManage/appManTerms";
import { AppManTerm } from "site/members/appManage/appManTerm";
import { AppManNewsletters } from "site/members/appManage/appManNewsletters";
import { AppManNewsletter } from "site/members/appManage/appManNewsletter";

import { AppManStudentManuals } from "site/members/appManage/appManStudentManuals";
import { AppManStudentManual } from "site/members/appManage/appManStudentManual";

import { AppManStudentJournals } from "site/members/appManage/appManStudentJournals";
import { AppManStudentJournal } from "site/members/appManage/appManStudentJournal";

import { AppManBeltTyingVideos } from "site/members/appManage/appManBeltTyingVideos";
import { AppManBeltTyingVideo } from "site/members/appManage/appManBeltTyingVideo";

import { DashboardForUser } from "site/members/forUser/dashboardForUser";
import { StudentDetailForUser } from "site/members/forUser/studentDetailForUser";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop } from "@material-ui/core";

import { useAuth, getIdToken } from "auth";
import env from "../env";
// import { Login } from 'auth';
// import { ChangePassword } from 'site/members/profile/changePassword';
// import { ForgotPassword } from 'auth/controls/forgotPassword/forgotPassword';
// import { ResetPassword } from 'auth/controls/resetPassword/resetPassword';
// import { VerifyEmail } from 'auth/controls/verifyEmail/verifyEmail';
// import { CreateAccountLoading } from 'auth/controls/createAccount/createAccount';
//import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';
//import { MenuLoader } from './menuLoader';

const MemberLayout = ({ children, ...rest }) => {
  const auth = useAuth();
  const isCancelled = React.useRef(false);
  const [sessionChecked, setSessionChecked] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    const checkAuth = async () => {
      if (!isCancelled.current) {
        const loggedIn = await auth.loggedIn();
        if (!loggedIn) {
          history.push(`/login`);
          return;
        } else {
          setSessionChecked(true);
        }
      }
    };
    checkAuth();
    return () => {
      //abortController.abort();
      isCancelled.current = true;
    };

    // eslint-disable-next-line
  }, [auth]);

  if (!sessionChecked) {
    return (
      <Backdrop open={!sessionChecked}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return <MainMemberLayout />;
};

export default MemberLayout;

function MainMemberLayout() {
  const [apiResult, setApiResult] = React.useState();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const checkAuth = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };

        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }

        const res = await fetch(env.apiBase + "/api/userprofile/getuserinfo", {
          //method: "post",
          // body: JSON.stringify({
          // }),
          signal: signal,
          ...options,
        });

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          }
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }
        setApiResult({ ...data.results });
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    checkAuth();

    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  } else if (
    apiResult &&
    apiResult.userInfo &&
    (apiResult.userInfo.memberType === 2 || apiResult.userInfo.memberType === 3)
  ) {
    return (
      <Switch>
        <DashboardLayoutForUserRoute
          exact
          path="/dashboard/student-detail/:studentId"
          component={StudentDetailForUser}
          apiResult={apiResult}
        />

        <DashboardLayoutForUserRoute
          exact
          path="/dashboard"
          component={DashboardForUser}
          apiResult={apiResult}
        />

        <Route path="/">
          <Redirect to="/dashboard" status="301" />
        </Route>
      </Switch>
    );
  } else if (
    apiResult &&
    apiResult.userInfo &&
    (apiResult.userInfo.memberType === 1 ||
      apiResult.userInfo.memberType === 99)
  ) {
    return (
      <Switch>
        <DashboardLayoutRoute
          exact
          path="/dashboard/student/new"
          component={UserStudent}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student/:studentId"
          component={UserStudent}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-detail/:studentId"
          component={StudentDetail}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/evaluation/:studentId/new"
          component={StudentEvaluation}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/evaluations/:studentId"
          component={StudentEvaluations}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/assign-class/:studentId/new"
          component={StudentAssignClass}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/assign-classes/:studentId"
          component={StudentAssignClasses}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/award-list/:studentId"
          component={StudentAwards}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/promotion-list/:studentId"
          component={StudentPromotions}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/attendance/:studentId"
          component={StudentAttendance}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/rego/:studentId"
          component={StudentRego}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/student/:studentId/parent/new"
          component={UserParent}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/student/:studentId/parent/:parentCoreId"
          component={UserParent}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard/students"
          component={UserStudents}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-award/new"
          component={SetupAward}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/terminology/new"
          component={AppManTerm}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/terminology/:appManagementId"
          component={AppManTerm}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/terminologies"
          component={AppManTerms}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/news-letter/new"
          component={AppManNewsletter}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/news-letter/:appManagementId"
          component={AppManNewsletter}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/news-letters"
          component={AppManNewsletters}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-manual/new"
          component={AppManStudentManual}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-manual/:appManagementId"
          component={AppManStudentManual}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-manuals"
          component={AppManStudentManuals}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-journal/new"
          component={AppManStudentJournal}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-journal/:appManagementId"
          component={AppManStudentJournal}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/student-journals"
          component={AppManStudentJournals}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/belt-tying-video/new"
          component={AppManBeltTyingVideo}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/belt-tying-video/:appManagementId"
          component={AppManBeltTyingVideo}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/belt-tying-videos"
          component={AppManBeltTyingVideos}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-award/new"
          component={SetupAward}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-award/new"
          component={SetupAward}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-award/:setupAwardId"
          component={SetupAward}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-awards"
          component={SetupAwards}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-belt-level/new"
          component={SetupBeltLevel}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-belt-level/:beltLevelId"
          component={SetupBeltLevel}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-belt-levels"
          component={SetupBeltLevels}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-belt-size/new"
          component={SetupBeltSize}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-belt-size/:beltSizeId"
          component={SetupBeltSize}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-belt-sizes"
          component={SetupBeltSizes}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-uniform/new"
          component={SetupUniform}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-uniform/:uniformId"
          component={SetupUniform}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-uniforms"
          component={SetupUniforms}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-glove/new"
          component={SetupGlove}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-glove/:gloveId"
          component={SetupGlove}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-gloves"
          component={SetupGloves}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-tshirt/new"
          component={SetupTshirt}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-tshirt/:tshirtId"
          component={SetupTshirt}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-tshirts"
          component={SetupTshirts}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-medical-condition/new"
          component={SetupMedical}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-medical-condition/:setupExInfoId"
          component={SetupMedical}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-medical-conditions"
          component={SetupMedicals}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-how-hear-about-us/new"
          component={SetupHowHear}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-how-hear-about-us/:setupExInfoId"
          component={SetupHowHear}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-how-hear-about-us-list"
          component={SetupHowHears}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-house-team/:houseTeamId"
          component={SetupHouseTeam}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-house-teams"
          component={SetupHouseTeams}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-program/new"
          component={SetupProgram}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-program/:programId"
          component={SetupProgram}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/property-metrics/setup-programs"
          component={SetupPrograms}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/studio-edit/new"
          component={StudioEdit}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          exact
          path="/dashboard/studio-edit/:studioId"
          component={StudioEdit}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          path="/dashboard/studio/:studioId"
          component={StudioSection}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          path="/dashboard/instructor/new"
          component={UserInstructor}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          path="/dashboard/instructor/:userId"
          component={UserInstructor}
          apiResult={apiResult}
        />
        <DashboardLayoutRoute
          path="/dashboard/instructors"
          component={UserInstructors}
          apiResult={apiResult}
        />

        {/* <DashboardLayoutRoute
      exact
      path="/dashboard/studio/new"
      component={Studio}
    /> */}

        <DashboardLayoutRoute
          exact
          path="/dashboard/studios"
          component={Studios}
          apiResult={apiResult}
        />

        <DashboardLayoutRoute
          exact
          path="/dashboard"
          component={Dashboard}
          apiResult={apiResult}
        />

        <Route path="/">
          <Redirect to="/dashboard" status="301" />
        </Route>
      </Switch>
    );
  }
  return <div>TEST11</div>;
}
