import React from "react";
import { useStore } from "../../../store";

export function Dashboard(props){
    //const {userInfo} = props;
    const { dispatch } = useStore();
    React.useEffect(() => {
        dispatch({ type: "SET_STATE", payload: {
            breadcrumbs : [
                {title : 'Dashboard', link : '/member/dashboard' }
            ]
        }});
        
        // eslint-disable-next-line
    }, []);
    
    return(
        <div>
            Dashboard
        </div>
    )
}