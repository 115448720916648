import React from "react";
//import Skeleton from "@material-ui/lab/Skeleton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from "react-router-dom";
import { Paper, Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    height: `calc(100vh - 120px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    //textAlign : 'center',
    //marginBottom: theme.spacing(2),
  },
  homeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      width: "800px",
      height: `830px`,
    },
  },
  textBox : {
    position: 'absolute',
    bottom : theme.spacing(1),
    left :'0px',
    width: '100%',
    padding : theme.spacing(1),
    color : 'white',
    backgroundColor : 'rgba(0,0,0,0.4)'
  },
  arrowIcon : {
    position: 'absolute',
    top : '50%',
    transform: 'translateY(-50%)',
    right: '0'
  }
}));

export function DashboardForUser(props) {
  const { studentId } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <img src="/assets/sample.jpeg" className={classes.homeImg} alt="test" />
        <Box className = {classes.textBox} component={Link} to = {`/dashboard/student-detail/${studentId}`}> 
          <Box style={{position: 'relative'}}>
            <Typography>Sangmin Shin</Typography>
            <Typography> Open Student details </Typography>
            <ArrowForwardIosIcon fontSize = "small" className = {classes.arrowIcon} />
          </Box>
        </Box>
      </Paper>
    </div>
  );
}