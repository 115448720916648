import React, { useEffect, useLayoutEffect } from "react";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useParams, Link, useHistory } from "react-router-dom";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import {
  Button,
  Typography,
  TextField,
  makeStyles,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      //marginLeft: theme.spacing(1),
      width: "-webkit-fill-available",
      //width: "50%",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    paddingBottom : theme.spacing(5)
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  buttonStyle2: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    //width: "-webkit-fill-available",
    //paddingLeft: theme.spacing(1),
    //paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    // width: "50%"
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
  },
  viewTitle: {},
  viewContents: {},
  box: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  // formControl: {
  //   //margin: theme.spacing(1),
  // },
}));

const schema = yup.object().shape({
  programName: yup.string().required("Program Name is required."),
  promotionFees: yup
    .number()
    .required("Promotion Fees is required.")
    .moreThan(-1)
    .integer(),
  trainingFees: yup
    .number()
    .required("Training Fees is required.")
    .moreThan(-1)
    .integer(),
  upgradeFees: yup
    .number()
    .required("Upgrade Fees is required.")
    .moreThan(-1)
    .integer(),
  programTimetableLink: yup
    .string()
    .required("Program Timetable Link is required."),
  ageFrom: yup.number().required("Age From is required.").moreThan(-1).integer(),
  ageTo: yup.number().required("Age To is required.").moreThan(-1).integer(),
  weeks: yup.number().required("Weeks is required."),
  programDescription: yup.string().nullable(true),
  upgradeEmailStudent: yup.string().nullable(true),
  upgradeEmailUniform: yup.string().nullable(true),
});

const weekList = [
  { name: "1 week", value: 1 },
  { name: "2 weeks", value: 2 },
  { name: "3 weeks", value: 3 },
  { name: "4 weeks", value: 4 },
  { name: "5 weeks", value: 5 },
  { name: "6 week", value: 6 },
  { name: "7 weeks", value: 7 },
  { name: "8 weeks", value: 8 },
  { name: "9 weeks", value: 9 },
  { name: "10 weeks", value: 10 },
  { name: "11 week", value: 11 },
  { name: "12 weeks", value: 12 },
  { name: "13 weeks", value: 13 },
  { name: "14 weeks", value: 14 },
  { name: "15 weeks", value: 15 },
  //{ name: "Over 15 weeks", value: 9999 },
];

export function Program(props) {
  const { userInfo } = props;
  const classes = useStyles();
  const { studioId, programId } = useParams();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [checkEidtMode, setCheckEidtMode] = React.useState(
    programId ? false : true
  );
  const [programData, setProgramData] = React.useState();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });

  //const { onChange, onBlur, name, ref } = register('test');
  async function submit(dataModel) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/program/saveprogram", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          studioId: studioId,
          programId: programId,
          userInfo : userInfo,
          programDefault: programData ? programData.programDefault : false
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();
      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/studio/${studioId}/programs`);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const changeViewMode = () => {
    setCheckEidtMode(!checkEidtMode);
  };

  const clickCancel = () => {
    if (programId) {
      setCheckEidtMode(false);
    } else {
      history.push(`/dashboard/studio/${studioId}/programs`);
    }
  };

  useEffect(() => {
    if (!programId || programId === "") {
      setValue("upgradeEmailStudent", "");
      setValue("upgradeEmailUniform", "");
      setLoading(false);
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/program/getprogram", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            programId: programId,
            studioId : studioId
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.program) {
          const programData = data.results.program;

          reset({
            programName : programData.programName,
            promotionFees : programData.promotionFees,
            trainingFees : programData.trainingFees,
            upgradeFees : programData.upgradeFees,
            programTimetableLink : programData.programTimetableLink,
            ageFrom : programData.ageFrom,
            ageTo : programData.ageTo,
            programDescription : programData.programDescription,
            upgradeEmailStudent : programData.upgradeEmailStudent,
            upgradeEmailUniform : programData.upgradeEmailUniform,
            weeks : programData.weeks
          });
          
          setProgramData(programData);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  } else if (!checkEidtMode) {
    return (
      <GetViewMode
        classes={classes}
        programData={programData}
        changeViewMode={changeViewMode}
        studioId={studioId}
      />
    );
  }
  return (
    <>
      
      <Typography>
        <DescriptionIcon /> Program Details
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.root}
        onSubmit={handleSubmit(submit)}
      >
        <TextField
          //id="outlined-basic"
          label="Program Name*"
          variant="outlined"
          error={!!errors.programName}
          helperText={errors?.programName?.message}
          size="small"
          className={classes.textField}
          disabled={programData && programData.programDefault && userInfo.memberType !== 99}
          {...register("programName")}
        />
        <TextField
          //id="outlined-basic"
          label="Promotion Fees*"
          variant="outlined"
          error={!!errors.promotionFees}
          helperText={errors?.promotionFees?.message}
          size="small"
          className={classes.textField}
          type="number"
          defaultValue={0}
          {...register("promotionFees")}
        />
        <TextField
          //id="outlined-basic"
          label="Training Fees*"
          variant="outlined"
          error={!!errors.trainingFees}
          helperText={errors?.trainingFees?.message}
          size="small"
          className={classes.textField}
          type="number"
          defaultValue={0}
          {...register("trainingFees")}
        />
        <TextField
          //id="outlined-basic"
          label="Upgrade Fees*"
          variant="outlined"
          error={!!errors.upgradeFees}
          helperText={errors?.upgradeFees?.message}
          size="small"
          className={classes.textField}
          type="number"
          defaultValue={0}
          {...register("upgradeFees")}
        />
        <TextField
          //id="outlined-basic"
          label="Program Timetable Link*"
          variant="outlined"
          error={!!errors.programTimetableLink}
          helperText={errors?.programTimetableLink?.message}
          size="small"
          className={classes.textField}
          {...register("programTimetableLink")}
        />
        <TextField
          //id="outlined-basic"
          label="Age From*"
          variant="outlined"
          error={!!errors.ageFrom}
          helperText={errors?.ageFrom?.message}
          size="small"
          className={classes.textField}
          type="number"
          defaultValue={0}
          disabled={programData && programData.programDefault && userInfo.memberType !== 99}
          {...register("ageFrom")}
        />
        <TextField
          //id="outlined-basic"
          label="Age To*"
          variant="outlined"
          error={!!errors.ageTo}
          helperText={errors?.ageTo?.message}
          size="small"
          className={classes.textField}
          type="number"
          defaultValue={0}
          disabled={programData && programData.programDefault && userInfo.memberType !== 99}
          {...register("ageTo")}
        />

        <FormControl
          variant="outlined"
          size="small"
          className={classes.textField}
          error={!!errors.weeks}
          disabled={programData && programData.programDefault && userInfo.memberType !== 99}
        >
          <InputLabel htmlFor="weeksInput"> Weeks* </InputLabel>
          <Select
            native
            label="weeks"
            inputProps={{
              name: "weeks",
              id: "weeksInput",
            }}
            {...register("weeks")}
            //labelWidth={80}
          >
            <option aria-label="None" value="" />
            {weekList.map((row, index) => {
              return (
                <option key={index} aria-label="None" value={row.value}>
                  {row.name}
                </option>
              );
            })}
          </Select>
          {!!errors.weeks && (
            <FormHelperText error id="weeks-error">
              {errors?.weeks?.message}
            </FormHelperText>
          )}
        </FormControl>

        <TextField
          //id="outlined-basic"
          label="Program Description"
          variant="outlined"
          error={!!errors.programDescription}
          helperText={errors?.programDescription?.message}
          size="small"
          className={classes.textField}
          multiline
          minRows={3}
          disabled={programData && programData.programDefault && userInfo.memberType !== 99}
          {...register("programDescription")}
        />

        <Typography>Upgrade Email Student Template</Typography>
        <CKEditor
          editor={ClassicEditor}
          config={{
            //toolbar: ['heading', '|', 'fontSize'],
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "|",
              "bulletedList",
              "numberedList",
            ],
          }}
          data={getValues("upgradeEmailStudent")}
          onInit={(editor) => {
            //console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue("upgradeEmailStudent", data);
            //ddd.onChange(data);
            //console.log({ event, editor, data });
          }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />

        <Typography style={{ marginTop: "2rem" }}>
          Upgrade Email With Advance Uniform Template
        </Typography>
        <CKEditor
          editor={ClassicEditor}
          config={{
            //toolbar: ['heading', '|', 'fontSize'],
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "|",
              "bulletedList",
              "numberedList",
            ],
          }}
          data={getValues("upgradeEmailUniform")}
          onInit={(editor) => {
            //console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue("upgradeEmailUniform", data);
            //ddd.onChange(data);
            //console.log({ event, editor, data });
          }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            size="small"
            disabled={isSubmitting}
            onClick={clickCancel}
            //component={Link}
            //to={`/dashboard/studio/${studioId}/programs`}
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>

      {serverErrors && serverErrors.length > 0 && (
        <ErrorMessage errors={serverErrors} />
      )}
    </>
  );
}

function GetViewMode({ classes, programData, changeViewMode, studioId }) {
  return (
    <Box className={classes.box}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Program Details
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/programs`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Typography className={classes.viewTitle} variant="caption">
          Program Name:
        </Typography>
        <Typography className={classes.viewContents}>
          {programData.programName}
        </Typography>
      </Box>

      <hr />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={3}>
          <Typography className={classes.viewTitle} variant="caption">
            Age From:
          </Typography>
          <Typography className={classes.viewContents}>
            {programData.ageFrom}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.viewTitle} variant="caption">
            Age To:
          </Typography>
          <Typography className={classes.viewContents}>
            {programData.ageTo}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.viewTitle} variant="caption">
            Promotion Fees:
          </Typography>
          <Typography className={classes.viewContents}>
            {programData.promotionFees}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.viewTitle} variant="caption">
            Training Fees:
          </Typography>
          <Typography className={classes.viewContents}>
            {programData.trainingFees}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.viewTitle} variant="caption">
            Upgrade Fees:
          </Typography>
          <Typography className={classes.viewContents}>
            {programData.upgradeFees}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Program Description:
          </Typography>
          <Typography className={classes.viewContents}>
            {programData.programDescription}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Logo:
          </Typography>

          <div style={{ maxWidth: "120px" }}>
            <img
              width="100%"
              src={`${
                programData.programIcon && programData.programIcon !== ""
                  ? programData.programIcon
                  : "/assets/no-image.png"
              }`}
              alt="logo"
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
