import React from "react";
import CakeIcon from "@material-ui/icons/Cake";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";
import PersonIcon from "@material-ui/icons/Person";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { variables } from "../../../cssInJs";
import utils from "utils";
import env from "../../../env";
import { Link } from "react-router-dom";
import {
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  //Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  //Grid,
  Radio,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  radio: {
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoTitle: {
    color: variables.colorGray1,
    marginTop: theme.spacing(2),
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
}));

export function StudentView(props) {
  const classes = useStyles();
  const {
    student,
    parent1,
    parent2,
    accountHolderInfo,
    //emergencyContactInfo
  } = props;

  const [showParent, setShowParent] = React.useState("parent1");
  const _studentImg =
    student && student.profilePic && student.profilePic !== ""
      ? `${env.resourceBase}/media/uploadimages/${student.profilePic}`
      : "/assets/default-user.jpg";

  const changeShowParent = (event) => {
    setShowParent(event.target.value);
  };

  return (
    <>
      <Box maxWidth="120px" margin="auto">
        <img src={_studentImg} width={"100%"} />
      </Box>

      <Typography align="center">{student.firstName}</Typography>

      <Typography align="center" display="block" variant="caption">
        {student.displayGender}
      </Typography>

      {showParent === "parent1" && (
        <Box>
          <Typography>Parent1</Typography>
          <Typography>{`${parent1.firstName} ${parent1.lastName}`}</Typography>
          <Typography> {parent1.email} </Typography>
          <Typography> {parent1.mobileNumber} </Typography>
        </Box>
      )}

      {showParent === "parent2" && (
        <Box>
          <Typography>Parent2</Typography>
          <Typography>{`${parent2.firstName} ${parent2.lastName}`}</Typography>
          <Typography> {parent2.email} </Typography>
          <Typography> {parent2.mobileNumber} </Typography>
        </Box>
      )}

      <div style={{ textAlign: "center" }}>
        <Radio
          checked={showParent === "parent1"}
          onChange={changeShowParent}
          value="parent1"
          name="radio-button-demo"
          inputProps={{ "aria-label": "parent1" }}
          size="small"
          className={classes.radio}
        />

        {parent2 && (
          <Radio
            checked={showParent === "parent2"}
            onChange={changeShowParent}
            value="parent2"
            name="radio-button-demo"
            inputProps={{ "aria-label": "parent2" }}
            size="small"
            className={classes.radio}
          />
        )}
      </div>

      <hr className={classes.hr} />

      <Typography className={classes.typoTitle}>
        {" "}
        Personal Information{" "}
      </Typography>
      <List
        component="nav"
        aria-label="main mailbox folders"
        className={classes.list}
      >
        <ListItem>
          <ListItemIcon>
            <CakeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={utils.site.formatDDMMYYYY(new Date(student.dateOfBirth))}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"N/A"} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <RoomIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`${student.address1}
                     ${student.address2}
                     ${student.state}
                     ${student.city}
                     ${student.country}`}
          />
        </ListItem>
      </List>

      <hr className={classes.hr} />

      <Typography className={classes.typoTitle}>
        Account Holder Information
      </Typography>

      <List
        component="nav"
        aria-label="main mailbox folders"
        className={classes.list}
      >
        <ListItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={accountHolderInfo.firstName} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"N/A"} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <SmartphoneIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={accountHolderInfo.mobileNumber} />
        </ListItem>
      </List>

      <hr className={classes.hr} />

      <Typography className={classes.typoTitle}>School Information</Typography>

      <List
        component="nav"
        aria-label="main mailbox folders"
        className={classes.list}
      >
        <ListItem>
          <ListItemIcon>
            <AccountBalanceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={student.school} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={student.principalName} />
        </ListItem>
      </List>

      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to={`/dashboard/student/${student.studentId}`}
          style={{ zIndex: 2 }}
        >
          Edit Profile
        </Button>
      </div>
    </>
  );
}
