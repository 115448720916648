import React from "react";
import { useStore } from "../../../store";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import { Loading, ErrorMessage } from "components";
import CircularProgress from "@material-ui/core/CircularProgress";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
//import DeleteIcon from "@material-ui/icons/Delete";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
//import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import env from "../../../env";
import Autocomplete from "@material-ui/lab/Autocomplete";
import utils from "../../../utils";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(2),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

//EnhancedTableHead
const headCells = [
  {
    id: "Day",
    numeric: false,
    disablePadding: false,
    label: "Day",
    hasSort: true,
  },
  {
    id: "StartTime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    hasSort: true,
  },
  {
    id: "ProgramName",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: true,
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Class Date",
    hasSort: true,
  },
  {
    id: "Students",
    numeric: false,
    disablePadding: false,
    label: "Students",
    hasSort: false,
  },

  {
    id: "IsCommitted",
    numeric: false,
    disablePadding: false,
    label: "Is Committed?",
    hasSort: false,
  },
];

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handleFilter } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Classes
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, terms, autoValue, inputAutoChange, searchType } = props;

  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Program
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={150}
        />
      </FormControl>

      <Autocomplete
        id="term"
        options={terms}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.termName}
        value={autoValue.term}
        getOptionSelected={(option, value) => option.termId === value.termId}
        onChange={(event, newValue) => {
          if (newValue) {
            inputAutoChange(
              event,
              newValue,
              "termId",
              newValue ? newValue.termId : null,
              "term"
            );
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.termName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Term"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
  termId: yup.string(),
});

export function Classes(props) {
  const { userInfo } = props;
  const { dispatch } = useStore();
  const { studioId } = useParams();
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Date");
  const [getClassesResult, setGetClassesResult] = React.useState();
  //const [openErrorScb, setOpenErrorScb] = React.useState(false);

  const [autoValue, setAutoValue] = React.useState({
    term: null,
  });

  const previousController = React.useRef();
  const timerController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [trigger, setTrigger] = React.useState(0);

  const {
    getValues,
    register,
    setValue,
    trigger: yupTrigger,
    //reset,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const searchType = () => {
    if (!getValues("termId")) {
      return;
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const inputAutoChange = (
    event,
    newValue,
    schemaId,
    schemaValue,
    autoVauleId
  ) => {
    setValue(schemaId, schemaValue);
    setAutoValue({
      ...autoValue,
      [autoVauleId]: newValue,
    });

    setBackdropOpen(true);
    setTrigger(trigger + 1);
  };

  const handleRequestSort = (event, property) => {
    //console.log("property", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (getClassesResult && getClassesResult.classes.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclasses", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            studioId: studioId,
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            userInfo: userInfo,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results && data.results.getClassesResult) {
          const { selectedTermId, termList } = data.results.getClassesResult;
          setValue("termId", selectedTermId);
          yupTrigger("termId");

          autoValue.term = termList.find((x) => x.termId === selectedTermId);
          setAutoValue({ ...autoValue });
          setGetClassesResult(data.results.getClassesResult);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
        setLoading(false);
        return;
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Classes", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          //numSelected={selected.length}
          handleFilter={handleFilter}
          studioId={studioId}
          //handleClickOpen={handleClickOpen}
        />

        {filterOpen && (
          <SearchPanel
            register={register}
            terms={getClassesResult.termList}
            autoValue={autoValue}
            inputAutoChange={inputAutoChange}
            searchType={searchType}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              //rowCount={getClassesResult.classes.length}
            />

            <TableBody>
              {getClassesResult.classes.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const displayDate = utils.site.formatDDMMYYYY(
                  new Date(row.date)
                );

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.classId}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      //padding="none"
                    >
                      <Link
                        to={`/dashboard/studio/${studioId}/class/${row.classId}`}
                      >
                        {row.displayDay}
                      </Link>
                    </TableCell>

                    <TableCell align="left">{`${row.startTime} - ${row.endTime}`}</TableCell>
                    <TableCell align="left">{row.programName}</TableCell>
                    <TableCell align="left">{displayDate}</TableCell>
                    <TableCell align="left">
                      {row.batchIdCount ? row.batchIdCount : "0"}
                    </TableCell>
                    <TableCell align="left">
                      {row.isCommitted ? (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <RemoveCircleIcon
                          fontSize="small"
                          style={{ color: "red" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          //count={data.length}
          count={
            getClassesResult.classes && getClassesResult.classes.length > 0
              ? getClassesResult.classes[0].totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
