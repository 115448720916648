import React from "react";
import { Route, useHistory, useLocation, Link } from "react-router-dom";
import { useAuth } from "auth";
//import { MenuLoader } from "./menuLoader";
//import { useStore } from "../store";
import { makeStyles, useTheme } from "@material-ui/core/styles";
//import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from "clsx";
//import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
//import ChevronRightIcon from "@material-ui/icons/ChevronRight";
//import MailIcon from "@material-ui/icons/Mail";
//import { ReactComponent as YourSvg } from "../site/members/svg/new_captain_ki_ahp.svg";
import { variables } from "../cssInJs";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import LensIcon from "@material-ui/icons/Lens";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  AppBar,
  //Divider,
  //Drawer,
  //Hidden,
  IconButton,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  Toolbar,
  Typography,
  CssBaseline,
  //Collapse,
  //Avatar,
  //Breadcrumbs,
  //Button,
  //Menu,
  //MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  //SvgIcon,
  //Link as MuiLink,
} from "@material-ui/core";

//import Ki from "../site/members/svg/new_captain_ki_ahp.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  bottomContainer: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    paddingBottom: theme.spacing(2),
    backgroundColor: "white",
  },
  bottom: {
    //width: 500,
    //position: "fixed",
    //width: "100%",
    //bottom: 0,
    //width: "100%",
    backgroundColor: "transparent",
  },
  bottomCenterItem: {
    "& .MuiBottomNavigationAction-label": {
      visibility: "hidden",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: variables.colorBlue2,
    color: "white",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: "40px",
    [theme.breakpoints.up("md")]: {
      minHeight: "48px",
    },
    // necessary for content to be below app bar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    minHeight: `calc(100vh - ${variables.parentLayoutHeightCap}px)`,
    maxWidth: '960px',
    margin : 'auto'
    //minHeight: "100vh",
    //width: `calc(100% - ${drawerWidth}px)`,
    //paddingBottom: theme.spacing(10),
    //width : '100%',
    //overflowAnchor : 'none'
  },
}));

const DashboardLayout = ({ children, ...rest }) => {
  const classes = useStyles();
  //const { userInfo } = rest;
  //const theme = useTheme();
  //const history = useHistory();
  //const { state } = useStore();
  const pathname = useLocation().pathname;
  const [naviInfo, setNaviInfo] = React.useState({
    title: "Home",
    bottomIndex: 0,
    backLink: null,
  });

  React.useEffect(() => {
    let bottomIndex = 0;
    let title = "Home";
    let backLink = null;

    if (pathname.toLowerCase().includes("/student-detail/")) {
      bottomIndex = 0;
      title = "Student Details";
      backLink = "/dashboard";
    }

    setNaviInfo({
      title: title,
      bottomIndex: bottomIndex,
      backLink: backLink,
    });
  }, [pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          {naviInfo.backLink && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              component={Link}
              to={naviInfo.backLink}
              // className={clsx(classes.menuButton, {
              //   [classes.hide]: open,
              // })}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          )}

          <Typography noWrap style={{ flexGrow: 1 }}>
            {naviInfo.title}
          </Typography>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>

      <div className={classes.bottomContainer}>
        <BottomNavigation
          value={naviInfo.bottomIndex}
          showLabels
          className={classes.bottom}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction label="Class" icon={<EditIcon />} />
          <BottomNavigationAction
            label="Nearby"
            icon={<LensIcon />}
            className={classes.bottomCenterItem}
          />
          <BottomNavigationAction
            label="Notification"
            icon={<NotificationsIcon />}
          />
          <BottomNavigationAction
            label="More"
            icon={<FormatListBulletedIcon />}
          />
        </BottomNavigation>
      </div>
    </div>
  );
};

const DashboardLayoutForUserRoute = ({
  component: RenderComponent,
  apiResult,
  breadcrumbItem,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout {...apiResult}>
          <RenderComponent {...matchProps} {...apiResult} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutForUserRoute;
