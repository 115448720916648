import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  //Typography,
  //Box,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
//import { Programs } from "./programs";
import {
  //useParams,
  Link,
} from "react-router-dom";

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "1px",
  },
  tab: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "72px",
    },
  },
  appBar: {
    backgroundColor: variables.colorLightGray,
    color: variables.fontColor,
  },
}));

function createTab(id, label, link = null) {
  return { id, label, link };
}

// function getValue(pathname) {
//   let result = "dashboard";
//   if (
//     pathname.includes("/program/new") ||
//     pathname.includes("/programs") ||
//     pathname.includes("/program/")
//   ) {
//     result = "programs";
//   } else if (
//     pathname.includes("/beltLevel/new") ||
//     pathname.includes("/beltLevels") ||
//     pathname.includes("/beltLevel/")
//   ) {
//     result = "beltLevels";
//   } else if (
//     pathname.includes("/term/new") ||
//     pathname.includes("/terms") ||
//     pathname.includes("/term/")
//   ) {
//     result = "terms";
//   } else if (
//     pathname.includes("/batche/new") ||
//     pathname.includes("/batches") ||
//     pathname.includes("/batche/")
//   ) {
//     result = "batches";
//   } else if (pathname.includes("/houseTeams")) {
//     result = "houseTeams";
//   }
//   return result;
// }

const tabItems = [
  createTab("dashboard", "Dashboard"),
  createTab("programs", "Programs"),
  createTab("houseTeams", "House Teams"),
  createTab("beltLevels", "Belt Levels"),
  createTab("awards", "Awards", "awards?awardtabtype=1"),
  createTab("settings", "Settings", "studio-setting"),
  createTab("terms", "Terms"),
  createTab("batches", "Classes"),
];

export function ConfigMenuStudio({ studioId, pathname }) {
  const classes = useStyles();
  const [value, setValue] = React.useState("dashboard");
  // const [value, setValue] = React.useState(() => {
  //   return getValue(pathname);
  // });
  //const { id, programId } = useParams();
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  React.useEffect(() => {
    let result = "dashboard";
    if (
      pathname.includes("/program/new") ||
      pathname.includes("/programs") ||
      pathname.includes("/program/")
    ) {
      result = "programs";
    } else if (
      pathname.includes("/beltLevel/new") ||
      pathname.includes("/beltLevels") ||
      pathname.includes("/beltLevel/")
    ) {
      result = "beltLevels";
    } else if (
      pathname.includes("/term/new") ||
      pathname.includes("/terms") ||
      pathname.includes("/term/")
    ) {
      result = "terms";
    } else if (
      pathname.includes("/batche/new") ||
      pathname.includes("/batches") ||
      pathname.includes("/batche/")
    ) {
      result = "batches";
    } else if (pathname.includes("/houseTeams")) {
      result = "houseTeams";
    } else if (pathname.includes("/awards")) {
      result = "awards";
    } else if (pathname.includes("/studio-setting")) {
      result = "settings";
    }

    setValue(result);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          aria-label="wrapped label tabs example"
          //onChange={handleChange}
        >
          {tabItems.map((row, index) => {
            const link = row.link ? row.link : row.id;
            return (
              <Tab
                key={row.id}
                value={row.id}
                label={row.label}
                wrapped
                component={Link}
                to={`/dashboard/studio/${studioId}/${link}`}
                className={classes.tab}
                {...a11yProps(row.id)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}
