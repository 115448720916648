import React from "react";
import env from "../../../env";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading, SnackbarMessage } from "components";
import clsx from "clsx";
//import { useTheme } from "@material-ui/core/styles";
import utils from "utils";
import { StudentView } from "./studentView";
import { StudentBottom } from "./studentBottom";
import AddIcon from "@material-ui/icons/Add";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";

import {
  Link,
  //useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  //IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  DialogActions,
  Grid,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 175px)",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useRightStyles = makeStyles((theme) => ({
  table: {
    //minWidth: 750,
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  autocomplete: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
}));

const headCells = [
  {
    id: "BatchName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    hasSort: true,
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    hasSort: false,
  },
  {
    id: "Day",
    numeric: false,
    disablePadding: false,
    label: "Day",
    hasSort: false,
  },
  {
    id: "StartTime",
    numeric: false,
    disablePadding: false,
    label: "Start Time",
    hasSort: false,
  },
  {
    id: "EndTime",
    numeric: false,
    disablePadding: false,
    label: "End Time",
    hasSort: false,
  },
  {
    id: "ClassCount",
    numeric: false,
    disablePadding: false,
    label: "Class Count",
    hasSort: false,
  },
  {
    id: "Actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    hasSort: false,
  },
];

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    studentId,
    deleteItems,
    //handleFilter,
    //currentTab,
    //activeStatus,
    //inActiveStatus,

    //studioId,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Assign Classes
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create" arrow>
          <Button
            component={Link}
            to={`/dashboard/assign-class/${studentId}/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>

        {/* <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="Active" arrow>
          <Button onClick={activeStatus}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="In Active" arrow>
          <Button onClick={inActiveStatus}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        {/*  */}
      </ButtonGroup>
    </Toolbar>
  );
};

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const RightItemView = (props) => {
  const classes = useRightStyles();
  const {
    studentId,
    student,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    assignClasses,
    isSelected,
    handleChange,
    handleSelectAllClick,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    deleteItems,
    handldeChangeClass,
  } = props;

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        studentId={studentId}
        deleteItems={deleteItems}
        //handleFilter={handleFilter}
        //activeStatus={activeStatus}
        //inActiveStatus={inActiveStatus}
      />

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={assignClasses.length}
          />

          <TableBody>
            {assignClasses.map((row, index) => {
              const isItemSelected = isSelected(row.assignClassId);
              const labelId = `enhanced-table-checkbox-${index}`;
              const displayDate = `${utils.site.formatDDMMYYYY(
                new Date(row.startDate)
              )}-${utils.site.formatDDMMYYYY(new Date(row.endDate))}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.assignClassId}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                      onChange={(event) =>
                        handleChange(event, row.assignClassId)
                      }
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <Link
                      to={`/dashboard/studio/${student.studioId}/batche/${row.batchId}`}
                    >
                      {row.batchName}
                    </Link>
                  </TableCell>
                  <TableCell align="left">{displayDate}</TableCell>
                  <TableCell align="left">{row.displayDay}</TableCell>
                  <TableCell align="left">{row.startTime}</TableCell>
                  <TableCell align="left">{row.endTime}</TableCell>
                  <TableCell align="left">-</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        handldeChangeClass(row);
                      }}
                    >
                      Change Class
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        //count={data.length}
        count={
          assignClasses && assignClasses.length > 0
            ? assignClasses[0].totalCount
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const SimpleDialog = (props) => {
  const {
    //terms,
    //cloneBatchTerms,
    //selected,
    //updateCloneBatchTerms,
    //submitCloneBath,
    //data,
    batches,
    openDialog,
    batch,
    handldeChangeClass2,
    submit,
    handleClose,
    assign,
  } = props;

  const classes = useDialogStyles();
  const _startDate = batch ? new Date(batch.startDate) : null;
  const _endDate = batch ? new Date(batch.endDate) : null;
  const _batches = assign
    ? batches.filter((x) => x.batchId !== assign.batchId)
    : batches;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={openDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        Change Class
      </DialogTitle>

      <DialogContent dividers={true}>
        <Autocomplete
          id="batchId"
          className={classes.autocomplete}
          options={_batches || []}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.batchName}
          getOptionSelected={(option, value) =>
            option.batchId === value.batchId
          }
          renderOption={(option) => (
            <React.Fragment>{option.batchName}</React.Fragment>
          )}
          value={batch || null}
          onChange={(event, newValue) => {
            handldeChangeClass2(event, newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Class*"
              variant="outlined"
              size="small"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />

        <div className={classes.datePicker}>
          <Typography variant="caption"> Start Date </Typography>
          <DatePicker
            selected={_startDate}
            disabled
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
        </div>

        <div className={classes.datePicker}>
          <Typography variant="caption"> End Date </Typography>
          <DatePicker
            selected={_endDate}
            disabled
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={submit}>
          Submit
        </Button>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function StudentAssignClasses() {
  const classes = useStyles();
  const { studentId } = useParams();
  const { dispatch } = useStore();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getstudentDetailRe, setGetstudentDetailRe] = React.useState();
  const previousController = React.useRef(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("BatchName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [trigger, setTrigger] = React.useState(0);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [autoValue, setAutoValue] = React.useState({
    batch: null,
  });

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChange = (event, assignClassId) => {
    const selectedIndex = selected.indexOf(assignClassId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, assignClassId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getstudentDetailRe.assignClasses.map(
        (n) => n.assignClassId
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    //console.log("property", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (
      getstudentDetailRe.assignClasses &&
      getstudentDetailRe.assignClasses.length > 0
    ) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteItems = async () => {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/student/deleteassignclasses",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            selectedIds: selected,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setSelected([]);
        setTrigger(trigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const handldeChangeClass = (assign) => {
    setGetstudentDetailRe({
      ...getstudentDetailRe,
      assign: assign,
      openDialog: true,
    });

    setAutoValue({
      ...autoValue,
      batch: null,
    });
  };

  const handleClose = () => {
    setGetstudentDetailRe({
      ...getstudentDetailRe,
      openDialog: false,
    });
  };

  const handldeChangeClass2 = (event, newValue) => {
    setAutoValue({
      ...autoValue,
      batch: newValue,
    });
  };

  const submit = async () => {
    setSnackbarOpen(false);

    if (autoValue.batch === null) {
      setSnackbarOpen(true);
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/changeassignclass", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          batchId: autoValue.batch.batchId,
          assignClassId: getstudentDetailRe.assign.assignClassId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setTrigger(trigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/student/getstudentassignclasses",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //setupAwardId: setupAwardId,
              studentId: studentId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results) {
          const _result = { ...data.results };

          setGetstudentDetailRe({
            ..._result,
            openDialog: false,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [trigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Students", link: "/dashboard/students" },
          { title: "Assign class", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {snackbarOpen && (
        <SnackbarMessage
          openStatus={true}
          severity="error"
          message="Please select class"
        />
      )}

      <SimpleDialog
        {...getstudentDetailRe}
        {...autoValue}
        handldeChangeClass2={handldeChangeClass2}
        handleClose={handleClose}
        submit={submit}
      />

      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar tab={"classes"} studentId={studentId} />
        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo {...getstudentDetailRe} />
            </Grid>

            <Grid item xs={9}>
              <Box className={classes.rightGridBox}>
                <RightItemView
                  {...getstudentDetailRe}
                  studentId={studentId}
                  order={order}
                  orderBy={orderBy}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  selected={selected}
                  isSelected={isSelected}
                  handleChange={handleChange}
                  handleSelectAllClick={handleSelectAllClick}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteItems={deleteItems}
                  handldeChangeClass={handldeChangeClass}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <StudentBottom />
      </Paper>
    </div>
  );
}
