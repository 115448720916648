import React from "react";
import { useStore } from "../../../store";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  //Grid,
  //Backdrop,
} from "@material-ui/core";
import { variables } from "../../../cssInJs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
//import PropTypes from "prop-types";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { Loading, ErrorMessage } from "components";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import env from "../../../env";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import utils from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    studioId,
    deleteBatches,
    activeBatches,
    inActiveBatches,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Private Lessons
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Batch" arrow>
          <Button
            component={Link}
            to={`/dashboard/studio/${studioId}/private-class/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeBatches}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveBatches}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Delete Batches" arrow>
          <Button onClick={deleteBatches}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
    "& button.MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, searchType, inputAutoChange, terms, term } = props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={135}
        />
      </FormControl>

      <Autocomplete
        id="termId"
        options={terms || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.termName}
        getOptionSelected={(option, value) => option.termId === value.termId}
        value={term || null}
        onChange={(event, newValue) => {
          inputAutoChange(
            event,
            "termId",
            "term",
            newValue ? newValue.termId : null,
            newValue
          );
        }}
        renderOption={(option) => (
          <React.Fragment>{option.termName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Term"
            variant="outlined"
            size="small"
            //error={!!errors.termId}
            //helperText={errors?.termId?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const headCells = [
  {
    id: "Photo",
    numeric: false,
    disablePadding: true,
    label: "Instructors Pic",
    hasSort: false,
  },
  {
    id: "FirstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    hasSort: true,
  },
  {
    id: "LastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    hasSort: false,
  },
  {
    id: "ProgramName",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date & Time",
    hasSort: true,
  },
  {
    id: "Price",
    numeric: false,
    disablePadding: false,
    label: "Price",
    hasSort: false,
  },
  {
    id: "BookingStatus",
    numeric: false,
    disablePadding: false,
    label: "Booking Status",
    hasSort: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const getStatusIcon = (status) => {
  if (status === 1) {
    return (
      <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
    );
  } else if (status === 99) {
    return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
  }
  return null;
};

const schema = yup.object().shape({
  search: yup.string(),
  termId: yup.string(),
});

export function PrivateLessons() {
  const { studioId } = useParams(); //termId
  const { dispatch } = useStore();
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Date");
  const [getApiResult, setGetApiResult] = React.useState();
  //const [cloneBatchItems, setCloneBatchItems] = React.useState([]);

  const [autoValue, setAutoValue] = React.useState({
    term: null,
  });

  const previousController = React.useRef();
  const timerController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const {
    getValues,
    register,
    setValue,
    trigger,
    reset,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const inputAutoChange = (event, id, id2, value1, value2) => {
    if (value2) {
      setValue(id, value1);
      trigger(id);
      setAutoValue({
        ...autoValue,
        [id2]: value2,
      });
    }
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }
    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getApiResult.privateClasses.map(
        (n) => n.privateClassId
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (getApiResult.appManages && getApiResult.appManages.length > 0) {
      setBackdropOpen(true);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, privateClassId) => {
    const selectedIndex = selected.indexOf(privateClassId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, privateClassId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  async function activePrivateLesson() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/activeBatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function deletePrivateLesson() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/deletebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  async function inActivePrivateLesson() {
    if (!selected || selected.length <= 0) {
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/inactivebatches", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedBatchIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
      } else {
        //history.push(`/dashboard/studios`);
        window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      setBackdropOpen(false);
    }
  }

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/classes/getprivateclasses",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              studioId: studioId,
              termId: autoValue.term ? autoValue.term.termId : null,
              page: page,
              rowsPerPage: rowsPerPage,
              order: order,
              orderBy: orderBy,
              ...getValues(),
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results) {
          const { termId, terms } = data.results;
          const _term = terms.find((x) => x.termId === termId);

          reset({
            termId: termId,
          });

          setAutoValue({
            term: _term,
          });

          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Private Lessons", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
          studioId={studioId}
          delete={deletePrivateLesson}
          active={activePrivateLesson}
          inActive={inActivePrivateLesson}
        />

        {filterOpen && (
          <SearchPanel
            {...getApiResult}
            {...autoValue}
            register={register}
            searchType={searchType}
            inputAutoChange={inputAutoChange}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={(getApiResult.privateClasses || []).length}
            />

            <TableBody>
              {(getApiResult.privateClasses || []).map((row, index) => {
                const isItemSelected = isSelected(row.privateClassId);
                const labelId = `enhanced-table-checkbox-${index}`;
                const statusIcon = getStatusIcon(row.status);
                const bookingStatusIcon = getStatusIcon(row.bookingStatus);
                const displayDate = utils.site.formatDDMMYYYY(
                  new Date(row.date)
                );
                const _img =
                  row.photo && row.photo !== ""
                    ? `${env.resourceBase}/media/uploadimages/${row.photo}`
                    : "/assets/no-image.png";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.privateClassId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={(event) =>
                          handleChange(event, row.privateClassId)
                        }
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <img width="50px" src={_img} alt="belt" />
                    </TableCell>

                    <TableCell align="left">
                      <Link
                        to={`/dashboard/studio/${studioId}/private-class/${row.privateClassId}`}
                      >
                        {row.firstName}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.lastName}</TableCell>
                    <TableCell align="left">{row.programName}</TableCell>
                    <TableCell align="left">{displayDate}</TableCell>
                    <TableCell align="left">{row.price}</TableCell>
                    <TableCell align="left">
                      {bookingStatusIcon && (
                        <Tooltip title={"Active"} arrow placement="top">
                          {bookingStatusIcon}
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {statusIcon && (
                        <Tooltip title={"Active"} arrow placement="top">
                          {statusIcon}
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            getApiResult.privateClasses && getApiResult.privateClasses.length > 0
              ? getApiResult.privateClasses[0].totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}