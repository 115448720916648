import React, { useState } from "react";
import { Route, Link, useHistory } from "react-router-dom";
import { useAuth } from "auth";
//import { MenuLoader } from "./menuLoader";
import { useStore } from "../store";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
//import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
//import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
//import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InboxIcon from "@material-ui/icons/MoveToInbox";
//import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ReactComponent as YourSvg } from "../site/members/svg/new_captain_ki_ahp.svg";
import CloseIcon from "@material-ui/icons/Close";
import { variables } from "../cssInJs";
//import SendIcon from "@material-ui/icons/Send";
//import DraftsIcon from "@material-ui/icons/Drafts";
import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CssBaseline,
  Collapse,
  Avatar,
  Breadcrumbs,
  Button,
  Menu,
  MenuItem,
  //SvgIcon,
  Link as MuiLink,
} from "@material-ui/core";

//import Ki from "../site/members/svg/new_captain_ki_ahp.svg";

const drawerWidth = variables.drawerWidth;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  // menuItem: {
  //   "&:focus": {
  //     backgroundColor: "white !important",
  //     "& .MuiListItemIcon-root": {
  //       color: "rgba(0, 0, 0, 0.54) !important",
  //     },
  //     "& .MuiListItemText-primary": {
  //       color: "rgba(0, 0, 0, 0.87) !important",
  //     },
  //   },
  // },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: variables.colorBlue2,
    color: "white",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  listItem: {
    color: "white",
    paddingLeft: "5px",
    paddingRight: "10px",

    "&.red": {
      backgroundColor: "red",
    },
    "&.blue": {
      backgroundColor: "blue",
    },
    "&.green": {
      backgroundColor: "green",
    },
    "&.orange": {
      backgroundColor: "orange",
    },
  },
  nested: {
    paddingLeft: theme.spacing(2) - 1,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    // display: "none",
    // [theme.breakpoints.down('sm')] : {
    //   display: 'flex'
    // }
  },
  drawerMain: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      //width: drawerWidth,
    },
  },
  drawer: {
    width: drawerWidth,
    whiteSpace: "nowrap",
    //flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      //width: theme.spacing(9) + 1,
      width: theme.spacing(6) - 2,
    },
  },
  list: {
    paddingTop: "0px",
  },
  istItemIcon: {
    minWidth: "35px",
  },
  listItemText: {
    whiteSpace: "normal",
    marginLeft: "5px",
    "& span": {
      fontSize: "0.9rem",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: `calc(100% - ${drawerWidth}px)`,
    position: "relative",
    minHeight: "100vh",
    //paddingBottom: theme.spacing(10),
    //width : '100%',
    //overflowAnchor : 'none'
  },

  muiLink: {
    color: "white !important",
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const DashboardLayout = ({ children, ...rest }) => {
  //const isCancelled = React.useRef(false);
  //const [sessionChecked, setSessionChecked] = React.useState(false);
  //const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userInfo } = rest;
  const auth = useAuth();
  const history = useHistory();
  const { state } = useStore();

  const usermanagementSub = [
    {
      id: "studios_studiomanagers",
      title: "Studio Managers",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "studios_instructors",
      title: "Instructors",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/instructors",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "studios_studentsearch",
      title: "Student Search",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/students",
      openSubMenu: false,
      superAdmin: false,
    },
  ];

  const setupOpSubs = [
    {
      id: "setup_programs",
      title: "Programs",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-programs",
      openSubMenu: false,
      superAdmin: true,
    },
    {
      id: "setup_houseteams",
      title: "House Teams",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-house-teams",
      openSubMenu: false,
      superAdmin: true,
    },
    {
      id: "setup_beltlevel",
      title: "Belt Level",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-belt-levels",
      openSubMenu: false,
      superAdmin: true,
    },
    {
      id: "setup_awards",
      title: "Awards",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-awards",
      openSubMenu: false,
      superAdmin: true,
    },
    {
      id: "setup_medicalconditions",
      title: "Medical Conditions",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-medical-conditions",
      openSubMenu: false,
      superAdmin: true,
    },
    {
      id: "setup_howhearaboutus",
      title: "How Hear About Us",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-how-hear-about-us-list",
      openSubMenu: false,
      superAdmin: true,
    },
    {
      id: "setup_beltsize",
      title: "Belt Size",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-belt-sizes",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "setup_uniformsize",
      title: "Uniform Size",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-uniforms",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "setup_glovesize",
      title: "Glove Size",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-gloves",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "setup_tshirtsize",
      title: "T-shirts Size",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/property-metrics/setup-tshirts",
      openSubMenu: false,
      superAdmin: false,
    },
  ];

  const appMaSubs = [
    {
      id: "appma_terms",
      title: "Terminology",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/terminologies",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "appma_newsletters",
      title: "Newsletters",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/news-letters",
      openSubMenu: false,
      superAdmin: false,
    },
    {
      id: "appma_studentmanuals",
      title: "Student Manuals",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/student-manuals",
      openSubMenu: false,
      superAdmin: false,
    },

    {
      id: "appma_studentjournals",
      title: "Student Journals",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/student-journals",
      openSubMenu: false,
      superAdmin: false,
    },

    {
      id: "appma_belttyingvideos",
      title: "Belt Tying Videos",
      style: "red",
      submenus: [],
      icon: <InboxIcon />,
      link: "/dashboard/belt-tying-videos",
      openSubMenu: false,
      superAdmin: false,
    },
  ];

  const _menus = [
    {
      id: "studios",
      title: "Studios",
      style: "red",
      submenus: [],
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "/dashboard/studios",
      openSubMenu: false,
    },
    {
      id: "appmanagement",
      title: "App Management",
      style: "blue",
      submenus: appMaSubs,
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "/",
      openSubMenu: false,
    },
    {
      id: "setupoperations",
      title: "Setup Operations",
      style: "green",
      submenus: setupOpSubs,
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "/",
      openSubMenu: false,
    },
    {
      id: "usermanagement",
      title: "User Management",
      style: "orange",
      submenus: usermanagementSub,
      icon: <InboxIcon style={{ color: "white" }} />,
      link: "",
      openSubMenu: false,
    },
  ];

  const handleDrawerToggle = () => {
    const _width = window.innerWidth;
    if (_width <= 600) {
      setMobileOpen(!mobileOpen);
    } else {
      setOpen(!open);
      setMobileOpen(false);
    }
  };

  const [menus, setMenus] = useState(_menus);
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handMenuClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    const found = menus.find((el) => el.id === id);
    found.openSubMenu = !found.openSubMenu;
    setMenus([...menus]);
  };

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <img src="/assets/logos/logo.jpg" width="100%" alt="logo" />
      </div>
      <Divider />
      <List className={classes.list}>
        {menus.map((item, index) => {
          const _submenus =  userInfo.memberType !== 99 ? item.submenus.filter((x) => x.superAdmin === false) : item.submenus; 
          return (
            <div key={item.id} index={index}>
              <ListItem
                button
                component={item.link !== "" ? Link : null}
                to={item.link}
                className={`${classes.listItem} ${item.style}`}
                onClick={(e) =>
                  _submenus.length > 0 ? handMenuClick(e, item.id) : null
                }
              >
                <ListItemIcon className={classes.istItemIcon}>
                  <YourSvg />
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  className={classes.listItemText}
                />
                {_submenus.length > 0 &&
                  (item.openSubMenu ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
              {_submenus.length > 0 && (
                <Collapse in={item.openSubMenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {_submenus.map((subItem, index) => (
                      <div key={subItem.id} index={index}>
                        <ListItem
                          button
                          className={classes.nested}
                          component={subItem.link !== "" ? Link : null}
                          to={subItem.link}
                        >
                          <ListItemIcon className={classes.istItemIcon}>
                            <YourSvg />
                          </ListItemIcon>
                          <ListItemText primary={subItem.title} />
                        </ListItem>
                      </div>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </>
  );

  const breadcrumbs = (
    <Breadcrumbs
      aria-label="breadcrumb"
      style={{ flexGrow: 1, color: "white" }}
    >
      {state.breadcrumbs &&
        state.breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumb.link) {
            return (
              <MuiLink
                className={classes.muiLink}
                color="initial"
                component={Link}
                to={breadcrumb.link}
                key={index}
              >
                {breadcrumb.title}
              </MuiLink>
            );
          } else {
            return (
              <Typography color="initial" key={index}>
                {" "}
                {breadcrumb.title}{" "}
              </Typography>
            );
          }
        })}
    </Breadcrumbs>
  );

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      document.activeElement.blur();
    }, 1);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const signout = (e) => {
    e.preventDefault();
    e.stopPropagation();

    auth
      .signout()
      .then((user) => {
        history.push(`/login`);
        //handleClose2();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // React.useEffect(() => {
  //   const checkAuth = async () => {
  //     if (!isCancelled.current) {
  //       const loggedIn = await auth.loggedIn();
  //       // console.log({ loggedIn })
  //       if (!loggedIn) {
  //         navigate('/member/login');
  //       } else {
  //         setSessionChecked(true);
  //       }
  //     }
  //   };
  //   checkAuth();
  //   return () => {
  //     isCancelled.current = true;
  //   };
  // }, [auth, navigate]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          {breadcrumbs}
          {/* <Typography noWrap style={{flexGrow : 1}}>
            Dash board
          </Typography> */}

          {/*  */}

          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            //variant="contained"
            //color="primary"
            onClick={handleClick2}
          >
            <Avatar src="/broken-image.jpg" />
            <Typography style={{ color: "white", marginLeft: "8px" }}>
              {`${userInfo.firstName} ${userInfo.lastName}`}
            </Typography>
          </Button>

          {/* <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu> */}

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose2}
          >
            {/* <StyledMenuItem>
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Sent mail" />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemIcon>
                <DraftsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
            </StyledMenuItem> */}

            <StyledMenuItem onClick={signout} className={classes.menuItem}>
              <ListItemIcon>
                <InboxIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </StyledMenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawerMain} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          ></Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            open
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

const DashboardLayoutRoute = ({
  component: RenderComponent,
  apiResult,
  breadcrumbItem,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout {...apiResult}>
          <RenderComponent {...matchProps} {...apiResult} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
