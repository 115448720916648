/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { useAuth } from "auth";
import { useRouter, SiteLink } from "components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { variables } from "../../../cssInJs";

import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import {
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Container,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";

//import style from "./style";

const useStyles = makeStyles((theme) => ({
  container: {
    //position: "absolute",
    //left: "50%",
    //top: "40%",
    //transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "1px 1px 5px 0px #e0e0e0",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  container2: {
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  },
  root: {
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  bg: {
    backgroundImage: `url("/assets/login-bg.7771fd96dfb79595c3b4.png")`,
    backgroundPosition: "center",
    position: "absolute",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    zIndex: "-1",
  },
  // formControl: {
  //   //margin: theme.spacing(1),
  // },
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .required("User Id is required.")
    .email("Email is required"),
  password: yup
    .string()
    .required("Password is required.")
    .min(6, "Password is too short - should be 6 chars minimum."),
  checkTerms: yup.boolean().oneOf([true], ""),
});

export function Login(props) {
  const {
    register,
    handleSubmit,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = React.useState("");

  const auth = useAuth();
  const { history } = useRouter();
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const submit = async (data) => {
    const { email, password } = data;
    await auth
      .signin(email, password)
      .then((user) => {
        setError('');
        history.push("/member/dashboard");
      })
      .catch((error) => {
        const obj = JSON.parse(error.message);
        console.log("error.message", obj.error.message);
        setError(obj.error.message);
      });
    
  }

  return (
    <>
      <div className={classes.container2}>
        <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
          <img src="/assets/logos/logo.jpg" width="103px" alt="logo" />
        </div>
        <Container maxWidth="sm" className={classes.container}>
          <h3 style={{ textAlign: "center" }}>Log In</h3>
          <form
            method="POST"
            noValidate=""
            onSubmit={handleSubmit(submit)}
            className={classes.root}
          >
            <TextField
              //id="outlined-basic"
              label="User ID"
              variant="outlined"
              error={!!errors.email}
              helperText={errors?.email?.message}
              size="small"
              {...register("email")}
            />

            <FormControl
              className={classes.textField}
              variant="outlined"
              size="small"
              error={!!errors.password}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                {...register("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={80}
              />
              {!!errors.password && (
                <FormHelperText error id="accountId-error">
                  {errors?.password?.message}
                </FormHelperText>
              )}
            </FormControl>

            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <SiteLink
                style={{ fontWeight: "500" }}
                to={"/member/forgotPassword"}
              >
                Forgot Password?
              </SiteLink>
            </div>

            <FormControl
              error={!!errors.checkTerms}
              component="fieldset"
              className={classes.formControl}
              size="small"
            >
              <FormControlLabel
                control={<Checkbox size="small" {...register("checkTerms")} />}
                className={`${
                  !!errors.checkTerms ? "Mui-error" : "Mui-error-non"
                }`}
                label="I accept Terms and Conditions"
              />
            </FormControl>

            <div>
              <Button
                variant="contained"
                color="primary"
                //size="large"
                className={classes.button}
                startIcon={
                  isSubmitting ? (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <ExitToAppOutlinedIcon />
                  )
                }
                type="submit"
                disabled={isSubmitting}
              >
                Sign in
              </Button>
            </div>

            {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
          </form>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button
              style={{ fontWeight: 600 }}
              color="primary"
              href="/signup"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push("/signup");
              }}
            >
              Studio Admin Sign Up
            </Button>
            {/* <SiteLink to={"/member/forgotPassword"}> Studio Admin Sign Up </SiteLink> */}
          </div>
          {error && <div style={{color: 'red', textAlign : 'center'}}>{error}</div>}
        </Container>
      </div>
      <div className={classes.bg} />
    </>
  );
}