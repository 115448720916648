import React from "react";
import { useStore } from "../../../store";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
//import LensIcon from "@material-ui/icons/Lens";
//import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import { getIdToken } from "auth";
//import queryString from "query-string";
import {
  makeStyles,
  //AppBar,
  //Tab,
  //Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //FormControlLabel,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  FormHelperText,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },

  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  formControl2: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },

  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    //color: "#fff",
    //position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },

  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },

  title_criteria: {
    flex: "1 1 100%",
  },

  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const {
    appManage,
    changeViewMode,
    selectedPrograms,
    uploadImgMemo,
    userInfo,
  } = props;
  const lastUpdated = utils.site.formatDDMMYYYY(
    new Date(appManage.dateUpdated)
  );
  const displaySelectedPrograms = selectedPrograms.map((x) => x.programName);

  console.log("appManage.isDefault", appManage.isDefault);

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Student Journal
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            
            {(appManage.isDefault === false || userInfo.memberType === 99) && (
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                size="small"
                startIcon={<AttachFileIcon />}
                onClick={changeViewMode}
              >
                Edit
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/student-journals`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Studio:
          </Typography>
          <Typography className={classes.viewContents}>
            {appManage.studioName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Program:
          </Typography>
          <Typography className={classes.viewContents}>
            {displaySelectedPrograms.toString()}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Post Title:
          </Typography>
          <Typography className={classes.viewContents}>
            {appManage.title}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"flex-start"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Youtube Video URL:
          </Typography>
          {appManage.videoURL &&
          (appManage.videoURL.includes("https://") ||
            appManage.videoURL.includes("http://")) ? (
            <div>
              <a
                href={appManage.videoURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue" }}
              >
                {appManage.videoURL}
              </a>
            </div>
          ) : (
            <Typography className={classes.viewContents}>
              {appManage.videoURL}
            </Typography>
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            File:
          </Typography>
          {appManage.uploadFile && appManage.uploadFile !== "" && (
            <div>
              <a
                href={`${env.resourceBase}/media/uploadimages/${appManage.uploadFile}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue" }}
              >
                <img src={uploadImgMemo} width="100px" alt="logo" />
              </a>
            </div>
          )}
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {appManage.displayStatus}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {lastUpdated}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>
            {" "}
            Adrian Adams{" "}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const schema = yup.object().shape({
  isDefault: yup.boolean(),
  studioId: yup
    .string()
    .when("isDefault", {
      is: false,
      then: (sch) => sch.required("Studio is required."),
    })
    .when("isDefault", { is: true, then: (sch) => sch.nullable(true) }),
  programIdText: yup
    .string()
    .when("isDefault", {
      is: false,
      then: (sch) => sch.required("Program is required."),
    })
    .when("isDefault", {
      is: true,
      then: (sch) => sch.nullable(true),
    }),
  title: yup.string().required("Title is required."),
  videoURL: yup.string().nullable(true),
  description: yup.string().required("Description is required."),
  uploadFile: yup.string().nullable(true),
});

export function AppManStudentJournal(props) {
  const { userInfo } = props;
  const { dispatch } = useStore();
  const { appManagementId } = useParams();
  const classes = useStyles();
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const filePondRef = React.useRef(null);
  const [autoValue, setAutoValue] = React.useState({
    studio: null,
    program: null,
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    watch,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { isDefault: false },
  });

  const { description, uploadFile, isDefault } = getValues();
  const [getApiResult, setGetApiResult] = React.useState();
  const history = useHistory();

  // const inputAutoChange = (event, id, id2, newValue, newValue2) => {
  //   setValue(id, newValue);
  //   trigger(id);
  //   setAutoValue({
  //     ...autoValue,
  //     [id2]: newValue2,
  //   });
  //   //setBackdropOpen(true);
  //   //setTrigger(trigger + 1);
  // };

  const submit = async (dataModel) => {
    //setServerErrors([]);
    //setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/appmanage/saveappmanageitem",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...dataModel,
            type: 4,
            appManagementId: appManagementId,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        //setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/student-journals`);
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      //setBackdropOpen(false);
    }
  };

  const changeViewMode = () => {
    getApiResult.editMode = false;
    setGetApiResult({ ...getApiResult });
  };

  const inputChangeStudio = async (event, newValue) => {
    if (newValue == null) {
      setValue("studioId", newValue ? newValue.studioId : null);
      setValue("programIds", null);
      trigger("studioId");

      setAutoValue({
        ...autoValue,
        studio: newValue,
        program: null,
      });
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/program/getdefaultandstudioprograms",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: newValue.studioId,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
        return;
      }

      if (data.results) {
        const _programs = data.results.programs;

        _programs.push({
          programId: "all",
          programName: "All",
        });

        setGetApiResult({
          ...getApiResult,
          programs: _programs,
        });
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }

    setValue("studioId", newValue ? newValue.studioId : null);
    setValue("programIds", null);
    trigger("studioId");

    setAutoValue({
      ...autoValue,
      studio: newValue,
      program: null,
    });
  };

  const inputChangeProgramIds = async (event, newValue) => {
    let _newValue = newValue;
    const _findedAllItem = _newValue.find((x) => x.programId === "all");

    if (_findedAllItem) {
      _newValue = getApiResult.programs.filter((x) => x.programId !== "all");
    }

    setValue("programIdText", _newValue.map((x) => x.programId).toString());
    trigger("programIdText");

    setAutoValue({
      ...autoValue,
      program: _newValue,
    });
  };

  const uploadImgMemo = React.useMemo(() => {
    let _imgUrl = "/assets/no-image.png";
    if (uploadFile && uploadFile !== "") {
      _imgUrl = `${env.resourceBase}/media/uploadimages/${uploadFile}`;
      if (uploadFile.includes(".pdf")) {
        _imgUrl = "/assets/pdf-icon.png";
      } else if (
        uploadFile.includes(".mp4") ||
        uploadFile.includes(".flv") ||
        uploadFile.includes(".m4v") ||
        uploadFile.includes(".mov")
      ) {
        _imgUrl = "/assets/video.png";
      }
    }
    return _imgUrl;

    // eslint-disable-next-line
  }, [uploadFile]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Student Journals", link: "/dashboard/student-journals" },
          { title: appManagementId ? "Details" : "Create", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(
          env.apiBase + "/api/appmanage/getappmanageitem",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //tshirtId: tshirtId,
              appManagementId: appManagementId,
              userInfo: userInfo,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          } else {
            throw new Error(`Response status: ${res.status}`);
          }
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results) {
          const _result = { ...data.results };

          let editMode = false;
          let newBatch = true;

          const _studios = _result.studios;
          const _appManage = _result.appManage;
          const _programs = _result.programs || [];

          if (_programs.length > 0) {
            _programs.push({
              programId: "all",
              programName: "All",
            });
          }

          if (_appManage) {
            editMode = true;
            newBatch = false;

            reset({
              isDefault: _appManage.isDefault,
              studioId: _appManage.studioId,
              programIdText: _appManage.programIds,
              title: _appManage.title,
              videoURL: _appManage.videoURL,
              description: _appManage.description,
              uploadFile: _appManage.uploadFile,
            });

            autoValue.studio = _studios.find(
              (x) => x.studioId === _appManage.studioId
            );

            autoValue.program = _programs.filter((x) =>
              _appManage.programIds.includes(x.programId)
            );

            setAutoValue({ ...autoValue });
          }

          setGetApiResult({
            ..._result,
            programs: _programs,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "isDefault" || name === "isDefault") {
        setValue("studioId", "");
        trigger(name);
        setAutoValue({
          ...autoValue,
          studio: null,
        });
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>
      <Paper className={classes.paper}>
        {getApiResult && getApiResult.editMode === true ? (
          <ViewMode
            {...getApiResult}
            selectedPrograms={autoValue.program || []}
            changeViewMode={changeViewMode}
            uploadImgMemo={uploadImgMemo}
            userInfo={userInfo}
          />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Student Journal
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              {/* {userInfo && userInfo.memberType === 99 && (
                <FormControl
                  error={!!errors.isDefault}
                  component="fieldset"
                  className={classes.formControl2}
                  size="small"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        {...register("isDefault")}
                        checked={isDefault}
                      />
                    }
                    className={`${
                      !!errors.isDefault ? "Mui-error" : "Mui-error-non"
                    }`}
                    label="Default"
                  />
                </FormControl>
              )} */}

              <Autocomplete
                id="studioId"
                disabled={isDefault}
                options={getApiResult.studios}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.studioName}
                getOptionSelected={(option, value) =>
                  option.studioId === value.studioId
                }
                value={autoValue.studio || null}
                onChange={(event, newValue) => {
                  inputChangeStudio(event, newValue);
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.studioName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Studio*"
                    variant="outlined"
                    size="small"
                    error={!!errors.studioId}
                    helperText={errors?.studioId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <Autocomplete
                id="programIdText"
                multiple
                options={getApiResult.programs}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.programName}
                getOptionSelected={(option, value) =>
                  option.programId === value.programId
                }
                value={autoValue.program || []}
                onChange={(event, newValue) => {
                  inputChangeProgramIds(event, newValue);
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.programName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Program*"
                    variant="outlined"
                    size="small"
                    error={!!errors.programIdText}
                    helperText={errors?.programIdText?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="Title*"
                variant="outlined"
                error={!!errors.title}
                helperText={errors?.title?.message}
                size="small"
                className={classes.textField}
                {...register("title")}
              />

              <TextField
                label="Video URL*"
                variant="outlined"
                error={!!errors.videoURL}
                helperText={errors?.videoURL?.message}
                size="small"
                className={classes.textField}
                {...register("videoURL")}
              />

              <Typography
                variant="caption"
                className={classes.marginDiv}
                style={{ marginBottom: "0px" }}
              >
                Description*
              </Typography>

              <Box>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "|",
                      "bulletedList",
                      "numberedList",
                    ],
                  }}
                  data={description}
                  onInit={(editor) => {
                    //console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue("description", data);
                    trigger("description");
                  }}
                />

                {!!errors.description && (
                  <FormHelperText error id="accountId-error">
                    {errors?.description?.message}
                  </FormHelperText>
                )}
              </Box>

              <Box className={classes.marginDiv}>
                <Typography
                  className={classes.marginDiv}
                  style={{ marginBottom: "8px" }}
                >
                  File
                </Typography>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  className={classes.gridContainer}
                >
                  <Grid item xs={3}>
                    
                    <FilePond
                      allowMultiple={false}
                      maxFiles={1}
                      name="uploadFile"
                      maxFileSize={"10MB"}
                      allowDrop={false}
                      allowReorder={true}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                      acceptedFileTypes={[
                        "image/*",
                        "application/pdf",
                        "video/mp4",
                        "video/flv",
                        "video/m4v",
                        "video/mov",
                      ]}
                      ref={filePondRef}
                      server={{
                        process: {
                          url: env.apiBase + "/api/file/uploadimage",
                          onload: (res) => {
                            setValue("uploadFile", res);
                            trigger("uploadFile");
                          },
                        },
                        fetch: null,
                        revert: null,
                      }}
                      onremovefile={() => {
                        setValue("uploadFile", "");
                        trigger("uploadFile");
                      }}
                    />

                  </Grid>
                  <Grid item xs={3}>
                    <div
                      style={{
                        width: "100px",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={uploadImgMemo} width="100%" alt="logo" />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          zIndex: "3",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          filePondRef.current.removeFiles();
                          setValue("uploadFile", "");
                          trigger("uploadFile");
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Typography variant="caption" style={{ color: "#6d6d6d" }}>
                  Allowed jpeg, jpg, png, gif only, Allowed pdf, Allowed video
                  mp4, flv, m4v, mov only and max 10MB.
                </Typography>
              </Box>

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/student-journals`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
