import React from "react";
import { useTheme } from "@material-ui/core/styles";
//import { variables } from "../../../cssInJs";

import {
  //AppBar,
  //Tab,
  //Tabs,
  //Box,
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  //Typography,
  //Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  //Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //DialogActions,
  Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    textAlign: "center",
    width: "calc(100% - 270px)",
    marginLeft: "270px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ulStyle: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0",
    margin: "0",
    listStyle: "none",
    justifyContent: "center",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
  liStyle: {
    padding: "0",
    margin: "0",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export function StudentBottom(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { setupAwards1, setupAwards2 } = props;
  return (
    <div className={classes.bottom}>
      <Grid container spacing={3}>
        <Grid item xs={3} className={classes.leftGrid}></Grid>
        <Grid item xs={9} style={{ backgroundColor: "rgba(0,0,0,0.1)" }}>

          <ul className={classes.ulStyle}>
            {(setupAwards1 || []).map((setupAwards1Item, index) => {
              const _img =
                setupAwards1Item.icon && setupAwards1Item.icon !== ""
                  ? `/assets/${setupAwards1Item.icon}`
                  : null;
              //const _img = null;
              return (
                <li
                  key={setupAwards1Item.setupAwardId}
                  className={classes.liStyle}
                >
                  <div>0</div>
                  {_img ? (
                    <img width="30px" src={_img} />
                  ) : (
                    setupAwards1Item.awardName
                  )}
                </li>
              );
            })}
          </ul>

          <ul className={classes.ulStyle} style={{marginTop : theme.spacing(1)}}>
            {(setupAwards2 || []).map((setupAwards2Item, index) => {
              const _img =
              setupAwards2Item.icon && setupAwards2Item.icon !== ""
                  ? `/assets/${setupAwards2Item.icon}`
                  : null;
              //const _img = null;
              return (
                <li
                  key={setupAwards2Item.setupAwardId}
                  className={classes.liStyle}
                >
                  <div>0</div>
                  {_img ? (
                    <img width="30px" src={_img} />
                  ) : (
                    setupAwards2Item.awardName
                  )}
                </li>
              );
            })}
          </ul>

        </Grid>
      </Grid>
    </div>
  );
}
