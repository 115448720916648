import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { 
  Snackbar
 } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SnackbarMessageV3(props) {
  
  const {
    severity,
    type,
    errors,
    handleCloseFunction
  } = props;

  const _openStatus = (type === "snackbar" && errors && errors.length > 0) ? true : false
  const _message = errors && errors.length > 0 ? errors[0].errorMessage : "";
  const { vertical, horizontal } = { vertical: "top", horizontal: "center" };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleCloseFunction();
  };

  return (
    <Snackbar
      open={_openStatus}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <Alert onClose={handleClose} severity={severity}>
        {_message}
      </Alert>
    </Snackbar>
  );
}