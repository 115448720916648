import React, { useEffect } from "react";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import env from "../../../env";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import utils from "../../../utils";
//import DateFnsUtils from "@date-io/date-fns";
import { Link, useParams } from "react-router-dom";
import {
  //SnackbarMessage,
  Loading,
  ErrorMessage,
  SnackbarMessageV2,
} from "components";
import { useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

import {
  //FormHelperText,
  //IconButton,
  //InputAdornment,
  //OutlinedInput,
  //InputLabel,
  //FormControl,
  //ButtonGroup,
  Button,
  Typography,
  FormHelperText,
  //Container,
  //Button,
  TextField,
  Grid,
  Box,
  //Checkbox,
  //FormControlLabel,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";

const useViewModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  buttonStyle2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  viewTitle: {},
  viewContents: {},
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      //marginLeft: theme.spacing(1),
      width: "-webkit-fill-available",
      //width: "50%",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
}));

const schema = yup.object().shape({
  year: yup.number().required("Year is required."),
  name: yup.string().required("Name is required."),
  startDate: yup.date().required("Start Date is required."),
  endDate: yup.date().required("End Date is required."),
  weeks: yup.number().required("Weeks is required."),
  description: yup.string(),
});

const weekList = [
  { name: "1 week", value: 1 },
  { name: "2 weeks", value: 2 },
  { name: "3 weeks", value: 3 },
  { name: "4 weeks", value: 4 },
  { name: "5 weeks", value: 5 },
  { name: "6 week", value: 6 },
  { name: "7 weeks", value: 7 },
  { name: "8 weeks", value: 8 },
  { name: "9 weeks", value: 9 },
  { name: "10 weeks", value: 10 },
  { name: "11 week", value: 11 },
  { name: "12 weeks", value: 12 },
  { name: "13 weeks", value: 13 },
  { name: "14 weeks", value: 14 },
  { name: "15 weeks", value: 15 },
  //{ name: "Over 15 weeks", value: 9999 },
];

const isWeekday = (date) => {
  const day = date.getDay();
  return day === 0;
};

export function Term() {
  const classes = useStyles();
  const { studioId, termId } = useParams();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getTermResult, setGetTermResult] = React.useState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { startDate, endDate } = getValues();
  const [openSnackbarMessage, setOpenSnackbarMessage] = React.useState({
    openStatus: false,
    message: null,
  });
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();

  // const [startDate, setStartDate] = React.useState();
  // const [minStartDate, setMinStartDate] = React.useState(new Date());
  // const [startDateDisabled, setStartDateDisabled] = React.useState(true);

  const [startDateInfo, setStartDateInfo] = React.useState({
    //date: null,
    minDate: new Date(),
    dateDisabled: true,
  });

  const [endDateInfo, setEndDateInfo] = React.useState({
    //date: null,
    minDate: new Date(),
    dateDisabled: true,
  });

  async function submit(dataModel) {
    setServerErrors([]);
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/term/saveterm", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          studioId: studioId,
          termId: termId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();
      if (data.errors && data.errors.length > 0) {
        setOpenSnackbarMessage({
          openStatus: true,
          message: data.errors[0].errorMessage,
        });
        return;
      } else {
        history.push(`/dashboard/studio/${studioId}/terms`);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const updateAllDateWeek = () => {
    const weeksValue = getValues("weeks");
    const startDateValue = getValues("startDate");

    if (
      startDateValue &&
      startDateValue !== "" &&
      weeksValue &&
      weeksValue !== ""
    ) {
      const _startDate = new Date(startDateValue);
      _startDate.setDate(_startDate.getDate() + parseInt(weeksValue) * 7);
      setValue("endDate", _startDate);
      trigger("endDate");
    }
  };

  const onChnageStartDate = (date) => {
    setValue("startDate", date);
    trigger("startDate");
    updateAllDateWeek();
  };

  // const onChnageEndDate = (date) => {
  //   //endDateInfo.date = date;
  //   setValue("endDate", date);
  //   trigger("endDate");
  //   updateAllDateWeek();
  // };

  const changeViewMode = () => {
    getTermResult.editMode = false;
    setGetTermResult({ ...getTermResult });
  };

  const handleCloseFunction = () => {
    setOpenSnackbarMessage(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/term/getterm", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            termId: termId,
          }),
          ...options,
        });

        if (!res.ok) {
          //throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getTermResult) {
          const _term = data.results.getTermResult.term;
          let editMode = false;
          let newBatch = true;
          if (_term) {
            editMode = true;
            newBatch = false;
            setValue("year", _term.year);
            setValue("name", _term.termName);
            setValue("startDate", new Date(_term.startDate));
            setValue("endDate", new Date(_term.endDate));
            setValue("weeks", _term.weeks);
            setValue("description", _term.description);

            startDateInfo.minDate = new Date();
            startDateInfo.dateDisabled = false;
            endDateInfo.minDate = new Date();
            //endDateInfo.dateDisabled = false;

            setStartDateInfo({ ...startDateInfo });
            setEndDateInfo({ ...endDateInfo });

            trigger([
              "year",
              "name",
              "startDate",
              "endDate",
              "weeks",
              "description",
            ]);
          }

          setGetTermResult({
            editMode: editMode,
            newBatch: newBatch,
            ...data.results.getTermResult,
          });
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "year" && type === "change") {
        const _year = value.year;
        const _today = new Date();
        const _todayYear = _today.getFullYear().toString();
        const _sDate =
          _year === _todayYear ? _today : new Date(`${_year}-01-01`);

        startDateInfo.minDate = _sDate;
        startDateInfo.dateDisabled = false;
        setStartDateInfo({ ...startDateInfo });

        setEndDateInfo({
          //date: null,
          minDate: new Date(),
          dateDisabled: true,
        });
      } else if (name === "weeks" && type === "change") {
        updateAllDateWeek();
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  } else if (getTermResult && getTermResult.editMode === true) {
    return (
      <GetViewMode
        getTermResult={getTermResult}
        changeViewMode={changeViewMode}
        studioId={studioId}
      />
    );
  }
  return (
    <>
      <SnackbarMessageV2
        {...openSnackbarMessage}
        handleCloseFunction={handleCloseFunction}
        severity="error"
      />

      <Typography>
        <DateRangeIcon /> Term
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.root}
        onSubmit={handleSubmit(submit)}
      >
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
          error={!!errors.year}
        >
          <InputLabel htmlFor="yearInput">Year*</InputLabel>
          <Select
            native
            label="year"
            inputProps={{
              name: "year",
              id: "yearInput",
            }}
            {...register("year")}
            //labelWidth={80}
          >
            <option aria-label="None" value="" />
            {getTermResult.yearList.map((row, index) => {
              return (
                <option key={row} value={row}>
                  {row}
                </option>
              );
            })}
          </Select>
          {!!errors.year && (
            <FormHelperText error id="year-error">
              {errors?.year?.message}
            </FormHelperText>
          )}
        </FormControl>

        <TextField
          //id="outlined-basic"
          label="Name*"
          variant="outlined"
          error={!!errors.name}
          helperText={errors?.name?.message}
          size="small"
          className={classes.textField}
          {...register("name")}
        />

        <div style={{ display: "flex", alignItems: "end" }}>
          <div className={classes.datePicker}>
            <Typography variant="caption">Start Date*</Typography>
            <DatePicker
              selected={startDate}
              onChange={onChnageStartDate}
              showIcon
              isClearable
              dateFormat="dd/MM/yyyy"
              placeholderText={"dd/mm/yyyy"}
              disabled={startDateInfo.dateDisabled}
              minDate={startDateInfo.minDate}
              toggleCalendarOnIconClick
              filterDate={isWeekday}
            />
            {!!errors.startDate && (
              <FormHelperText error id="startDate-error">
                {errors?.startDate?.message}
              </FormHelperText>
            )}
          </div>

          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            error={!!errors.weeks}
          >
            <InputLabel htmlFor="weeksInput"> Weeks* </InputLabel>
            <Select
              native
              label="weeks"
              inputProps={{
                name: "weeks",
                id: "weeksInput",
              }}
              {...register("weeks")}
              //labelWidth={80}
            >
              <option aria-label="None" value="" />
              {weekList.map((row, index) => {
                return (
                  <option key={index} aria-label="None" value={row.value}>
                    {row.name}
                  </option>
                );
              })}
            </Select>
            {!!errors.weeks && (
              <FormHelperText error id="weeks-error">
                {errors?.weeks?.message}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <div style={{ display: "flex", alignItems: "end" }}>
          <div className={classes.datePicker}>
            <Typography variant="caption">End Date*</Typography>
            <DatePicker
              selected={endDate}
              //onChange={onChnageEndDate}
              showIcon
              isClearable
              dateFormat="dd/MM/yyyy"
              placeholderText={"dd/mm/yyyy"}
              //disabled={endDateInfo.dateDisabled}
              disabled
              minDate={endDateInfo.minDate}
              toggleCalendarOnIconClick
              filterDate={isWeekday}
            />
            {!!errors.endDate && (
              <FormHelperText error id="endDate-error">
                {errors?.endDate?.message}
              </FormHelperText>
            )}
          </div>
        </div>

        <TextField
          //id="outlined-basic"
          label="Description"
          variant="outlined"
          error={!!errors.description}
          helperText={errors?.description?.message}
          size="small"
          className={classes.textField}
          multiline
          minRows={3}
          style={{ width: "100%" }}
          {...register("description")}
        />

        <div
          className={classes.buttonContainer}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            component={Link}
            size="small"
            to={`/dashboard/studio/${studioId}/terms`}
            disabled={isSubmitting}
            //color="primary"
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
      {/* {serverErrors && serverErrors.length > 0 && (
        <SnackbarMessage openStatus = {true} severity="error" message = {serverErrors[0].errorMessage} />
      )} */}
    </>
  );
}

function GetViewMode(props) {
  const classes = useViewModeStyles();
  const { getTermResult, changeViewMode, studioId } = props;
  const { term } = getTermResult;
  const displayStartDate = utils.site.formatDDMMYYYY(new Date(term.startDate));
  const displayEndDate = utils.site.formatDDMMYYYY(new Date(term.endDate));
  const displayDateUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(term.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Term Details
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/terms`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Year:
          </Typography>
          <Typography className={classes.viewContents}>{term.year}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {term.termName}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Start Date:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayStartDate}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            End Date:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayEndDate}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            No Of Weeks:
          </Typography>
          <Typography className={classes.viewContents}>{term.weeks}</Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Description:
          </Typography>
          <Typography className={classes.viewContents}>
            {term.description}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayDateUpdated}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
