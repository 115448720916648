import React from "react";
import { useStore } from "../../../store";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  //DialogContentText,
  DialogActions,
  Box,
  Grid,
  //Backdrop,
} from "@material-ui/core";

import { variables } from "../../../cssInJs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { Loading, ErrorMessage, SnackbarMessageV2 } from "components";
import CircularProgress from "@material-ui/core/CircularProgress";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
//import DeleteIcon from "@material-ui/icons/Delete";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import SearchIcon from "@material-ui/icons/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import env from "../../../env";
//import Autocomplete from "@material-ui/lab/Autocomplete";
//import utils from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
  weekContainer: {
    "&.odd": {
      backgroundColor: "#fbfbfb",
    },
    "&.even": {
      backgroundColor: "#fff",
    },
  },
  weekTitle: {
    borderBottom: "1px solid rgba(0,0,0,.1)",
  },
  startTime: {
    background: "green",
    padding: theme.spacing(1),
  },
  assign: {
    display: "flex",
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
  index: {},
  name: {
    //padding: "5px",
    //marginLeft : theme.spacing(1),
    backgroundColor: "red",
    flex: "1 1 auto",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    position: "relative",
    cursor: "pointer",
  },
  belt: {
    //float: "right",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: theme.spacing(1),
  },
  reserve: {
    width: "100%",
    marginTop: theme.spacing(1),
    backgroundColor: "gray",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 auto",
  },
}));

const useReDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  formRoot: {
    "& > *": {
      //marginTop: theme.spacing(1),
      //marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      //width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },

  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  programName: {
    backgroundColor: variables.colorLightGray,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    //numSelected,
    //handleFilter,
    //currentTab,
    //activeSetupBeltSize,
    //inCctiveSetupBeltSize,
    //deleteItems,
    //studioId,
  } = props;
  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title}>Class List</Typography>
      <Box>
        <div style={{ display: "inline" }}>
          <span
            style={{
              display: "inline-block",
              backgroundColor: "rgba(212,128,24,.34)",
              width: "15px",
              height: "15px",
              marginRight: "5px",
              verticalAlign: "middle",
            }}
          />
          <Typography variant="caption">Reserve</Typography>
        </div>

        <div style={{ display: "inline", marginLeft: "16px" }}>
          <span
            style={{
              display: "inline-block",
              backgroundColor: "red",
              width: "15px",
              height: "15px",
              marginRight: "5px",
              verticalAlign: "middle",
            }}
          />
          <Typography variant="caption">Future</Typography>
        </div>

        <div style={{ marginLeft: "16px", display: "inline" }}>
          <span
            style={{
              display: "inline-block",
              backgroundColor: "rgba(17,17,17,.42)",
              width: "15px",
              height: "15px",
              marginRight: "5px",
              verticalAlign: "middle",
            }}
          />
          <Typography variant="caption">Upgrade</Typography>
        </div>
      </Box>
    </Toolbar>
  );
};

const ReserveDialog = (props) => {
  const {
    open,
    batch,
    isEdit,
    closeReserveDialog,
    errors,
    register,
    handleSubmit,
    submit,
    isSubmitting,
    removeReStudent
  } = props;
  const classes = useReDialogStyles();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        className={classes.formRoot}
        onSubmit={handleSubmit(submit)}
      >
        <DialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">Reserve Student</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeReserveDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {batch && (
          <DialogContent dividers>
            <Typography className={classes.programName}>
              {`${batch.programName} (${batch.startTime})`}
            </Typography>
            <TextField
              label="First Name*"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              size="small"
              className={classes.textField}
              {...register("firstName")}
            />

            <TextField
              label="Last Name*"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              size="small"
              className={classes.textField}
              {...register("lastName")}
            />

            <TextField
              label="Contact Number*"
              variant="outlined"
              error={!!errors.contactNumber}
              helperText={errors?.contactNumber?.message}
              size="small"
              className={classes.textField}
              {...register("contactNumber")}
            />

            <TextField
              label="Contact Person*"
              variant="outlined"
              error={!!errors.contactPerson}
              helperText={errors?.contactPerson?.message}
              size="small"
              className={classes.textField}
              {...register("contactPerson")}
            />
          </DialogContent>
        )}

        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
          {isEdit && (
            <Button
              color="primary"
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              disabled={isSubmitting}
              onClick={removeReStudent}
            >
              Remove
            </Button>
          )}

          <Button color="primary" onClick={closeReserveDialog}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  contactNumber: yup.string().required("Contact Number is required."),
  contactPerson: yup.string().required("Contact Person is required."),
});

export function Classlist() {
  const { studioId } = useParams(); //termId
  const { dispatch } = useStore();
  const classes = useStyles();
  //const [filterOpen, setFilterOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [openSnackbarMessage, setOpenSnackbarMessage] = React.useState({
    openStatus: false,
    message: null,
  });
  //const [order, setOrder] = React.useState("asc");
  //const [orderBy, setOrderBy] = React.useState("ProgramName");
  const [getCLResult, setGetCLResult] = React.useState();
  const [tab, setTab] = React.useState();
  //const [openErrorScb, setOpenErrorScb] = React.useState(false);

  const previousController = React.useRef();
  const timerController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  //const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //const [page, setPage] = React.useState(0);
  const [apiTrigger, setApiTrigger] = React.useState(0);

  const [reDialog, setReDialog] = React.useState({
    open: false,
    batch: null,
    isEdit: false,
    reserveStudentId : null
  });

  const {
    register,
    handleSubmit,
    //setValue,
    reset,
    //getValues,
    //trigger,
    //watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { isDefault: false },
  });

  const handleChange = (event, newValue) => {
    // setCurrentTab(newValue);
    // setOrder("asc");
    // setOrderBy("Position");
    // setValue("search", "");
    // setBackdropOpen(true);
    // setTrigger(trigger + 1);
    setTab(newValue);
    setApiTrigger(apiTrigger + 1);
  };

  const openReserveDialog = (batch, reserveStudent) => {
    reset({
      firstName: reserveStudent !== null ? reserveStudent.firstName : null,
      lastName: reserveStudent !== null ? reserveStudent.lastName : null,
      contactNumber:
        reserveStudent !== null ? reserveStudent.contactNumber : null,
      contactPerson:
        reserveStudent !== null ? reserveStudent.contactPerson : null,
    });

    setReDialog({
      open: true,
      batch: batch,
      isEdit: reserveStudent !== null ? true : false,
      reserveStudentId : reserveStudent !== null ? reserveStudent.reserveStudentId : null,
    });
  };

  const closeReserveDialog = () => {
    reDialog.open = false;
    setReDialog({ ...reDialog });
  };

  const handleCloseFunction = () => {
    setOpenSnackbarMessage(false);
  };

  const removeReStudent = async () => {
    
    setBackdropOpen(true);

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    
    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/removerestudent", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          reserveStudentId : reDialog.reserveStudentId
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      reset({
        firstName: null,
        lastName: null,
        contactNumber: null,
        contactPerson: null,
      });

      setReDialog({
        open: false,
        batch: null,
        isEdit: false,
        reserveStudentId : null
      });

      if (data.errors && data.errors.length > 0) {
        timerController.current = setTimeout(() => {
          setBackdropOpen(false);
          setOpenSnackbarMessage({
            openStatus: true,
            message: data.errors[0].errorMessage,
          });
        }, 500);
        return;
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const submit = async (dataModel) => {
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    const { batch, reserveStudentId } = reDialog;
    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/saverestudent", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          batchId: batch.batchId,
          reserveStudentId : reserveStudentId
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      reset({
        firstName: null,
        lastName: null,
        contactNumber: null,
        contactPerson: null,
      });

      setReDialog({
        open: false,
        batch: null,
        isEdit: false,
        reserveStudentId : null
      });

      if (data.errors && data.errors.length > 0) {
        timerController.current = setTimeout(() => {
          setBackdropOpen(false);
          setOpenSnackbarMessage({
            openStatus: true,
            message: data.errors[0].errorMessage,
          });
        }, 500);
        return;
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclasslist", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            programId : tab
            //page: page,
            //rowsPerPage: rowsPerPage,
            //order: order,
            //orderBy: orderBy,
            //...getValues(),
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results) {
          const { selectedProgramId } = data.results;
          setTab(selectedProgramId);
          setGetCLResult({
            ...data.results,
          });

        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);


  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Class List", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);


  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV2
        {...openSnackbarMessage}
        handleCloseFunction={handleCloseFunction}
        severity="error"
      />

      <ReserveDialog
        {...reDialog}
        closeReserveDialog={closeReserveDialog}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        submit={submit}
        isSubmitting={isSubmitting}
        removeReStudent = {removeReStudent}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar {...getCLResult} />
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            borderColor: "rgba(0,0,0,.1)",
          }}
        />
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getCLResult.programs.map((row, index) => {
              return (
                <Tab
                  key={row.programId}
                  value={row.programId}
                  label={row.programName}
                  wrapped
                  {...a11yProps(row.programId)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <Box p={1}>
          <Grid container spacing={0}>
            {getCLResult.batchWeeklies.map((week, index) => {
              return (
                <Grid
                  key={week.dayType}
                  item
                  xs={2}
                  className={clsx(
                    classes.weekContainer,
                    index % 2 == 0 ? "odd" : "even"
                  )}
                >
                  <Typography align="center" className={classes.weekTitle}>
                    {week.displayDay}
                  </Typography>
                  {week.batches.length > 0 ? (
                    week.batches.map((batch, index) => {
                      return (
                        <Box p={1} key={batch.batchId}>
                          <Typography className={classes.startTime}>
                            {batch.startTime}
                          </Typography>
                          {batch.assignClasses.map((assign, index) => {
                            return (
                              <div
                                key={assign.assignClassId}
                                className={classes.assign}
                              >
                                <div className={classes.index}>{`${
                                  index + 1
                                }.`}</div>
                                <Typography className={classes.name}>
                                  {`${assign.firstName} ${assign.lastName}`}
                                  <img
                                    width="20px"
                                    src="/assets/no-image.png"
                                    className={classes.belt}
                                  />
                                </Typography>
                              </div>
                            );
                          })}
                          {(batch.reserveStudents || []).map(
                            (reserveStudent, index) => {
                              return (
                                <div
                                  key={reserveStudent.reserveStudentId}
                                  className={classes.assign}
                                >
                                  <div className={classes.index}>{`${
                                    batch.assignClasses.length + (index + 1)
                                  }.`}</div>
                                  <Typography
                                    className={classes.name}
                                    onClick={() =>
                                      openReserveDialog(batch, reserveStudent)
                                    }
                                  >
                                    {`${reserveStudent.firstName} ${reserveStudent.lastName}`}
                                  </Typography>
                                </div>
                              );
                            }
                          )}

                          <Button
                            //variant="contained"
                            //color="secondary"
                            className={classes.reserve}
                            startIcon={<AddIcon />}
                            onClick={() => openReserveDialog(batch, null)}
                          >
                            Reserve
                          </Button>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography align="center">N/A</Typography>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
