import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRouter } from "./components";
import { StoreProvider } from "./store";
//import { ViewPage } from "./cms/pages/viewPage/viewPage";
//import { EditPage } from "./cms/pages/editPage/editPage";
//import { TopHeader } from "./layout/topHeader/topHeader";
import { AppVersionPage } from "./components";
import { MediaQueryStatus } from "./components";
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from "react-helmet-async";
import { AppRouteChanged } from "./AppRouteChanged";
//import AOS from "aos";
import DashboardNoAuthLayoutRoute from "layout/dashboardNoAuthLayout";
import MemberLayout from "layout/memberLayout";
import { ProvideAuth, Login, Signup } from "auth";

//import { useStore } from "./store";
//import { variables } from './cssInJs'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// import { useScrollPosition } from '@n8tb1t/use-scroll-position';
// import { Footer } from './layout/footer/footer';
// import { BottomDock } from "./layout/bottomDock/bottomDock";
// import { BlogView } from './site/blog/blogView';

const theme = createTheme({
  typography: {
    fontFamily: "proxima-nova",
  }
  // overrides : {
  //   MuiOutlinedInput : {
  //     borderRadius: '0px'
  //   }
  // }
  // '& .MuiOutlinedInput-root' : {
  //
  // }
});

function AppLayout(props) {
  const { pathname } = useRouter();
  
  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  // useScrollPosition(({ prevPos, currPos }) => {
  //   //console.log(currPos.x)
  //   //console.log(currPos.y);
  //   if (currPos.y > 90) {
  //     document.querySelector('body').classList.add('scrolled')
  //   } else {
  //     document.querySelector('body').classList.remove('scrolled')
  //   }
  //   //setScrolled(currPos.y > 90)
  // }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))

  //const pathFiltered = (pathname || '').toLocaleLowerCase();
  let specialClassName = "";
  //  if (pathFiltered.indexOf('/digital-sensor') >= 0) specialClassName = 'digital-sensor-page';
  //  else if (pathFiltered === '/brilliance') specialClassName = 'brilliance-page';

  useEffect(() => {
    // AOS.init({
    //   duration: 1200,
    //   //delay:400,
    //   //once:true
    // });
  }, []);

  return (
    <div
      className={`body-content ${
        pathname === "/" ? "homepage" : "subpage"
      } ${specialClassName}`}
    >
      <AppRouteChanged />
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            {/* <Route path="/cms/page/render/:id" children={<EditPage />} /> */}
            {/* <Route path="/blog/:id" children={<BlogView />} /> */}

            <DashboardNoAuthLayoutRoute path="/login" component={Login} />
            <DashboardNoAuthLayoutRoute path="/signup" component={Signup} />
            
            <Route path="/dashboard" component={MemberLayout} />
            {/* <Route path="/dashboard">
              <MemberLayout />
            </Route> */}

            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>

            <Route path="/">
              {/* <ViewPage /> */}
              <Redirect to="/dashboard" status="301" />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      {/* <BottomDock></BottomDock> */}
      {/* <MediaQueryStatus /> */}
      <CommonVideoPopup />
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
        <ProvideAuth>
          <ThemeProvider theme={theme}>
            <Router>
              <AppLayout />
            </Router>
          </ThemeProvider>
        </ProvideAuth>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
